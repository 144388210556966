
  <div class="d-flex flex-column gap-3">
    <div class="card card-gray-200 p-3 border-rounded border-0">
      <h5 class="mb-1">{{ delivery().subject.title }}</h5>
      <small class="text-muted">{{ delivery().task.description }}</small>
      <p class="mb-1">{{ delivery().subject.description }}</p>
    </div>

    <div class="editing-card">
      <div class="card">
        <div class="card-body">
          @if(deliverableForm){
          <form
            [formGroup]="deliverableForm"
            role="form"
            class="d-flex flex-column w-100"
            *transloco="let t"
          >
            <div class="input-self">
              <label class="form-label h3 fw-bold">
                {{ t('content_management.heading') }}
              </label>
              <p class="mb-2 form-text" *transloco="let t; scope: 'contributions'">
                {{ t('contributions.heading_help') }}
              </p>

              @for(headingControl of getTranslatableFormFieldControls('heading'); track
              headingControl){ @if(deliverableForm.get(headingControl.key)){

              <!-- <h5>Debugging</h5>
              <p>
              <b>headingControl.type === 'target'</b>: {{headingControl.type === 'target'}}<br>
              <b>sourceLanguage()</b>: {{sourceLanguage()}}<br>
              <b>allTargetLanguageFormControlsAreNull()</b>: {{allTargetLanguageFormControlsAreNull()}}<br>
              <b>deliverableForm.get(headingControl.key).disabled</b>: {{deliverableForm.get(headingControl.key).disabled}}<br>
              </p> -->

              <div
                class="input-group textarea-control mb-3"
                [ngClass]="{
                  'ng-invalid':
                    deliverableForm.get(headingControl.key)?.invalid &&
                    (deliverableForm.get(headingControl.key)?.touched ||
                      deliverableForm.get(headingControl.key)?.dirty),
                  'ng-valid':
                    deliverableForm.get(headingControl.key)?.valid &&
                    deliverableForm.get(headingControl.key)?.touched &&
                    deliverableForm.get(headingControl.key)?.dirty
                }"
              >
                <span class="input-group-text">
                  {{
                    headingControl.type === 'source'
                      ? sourceLanguage()?.name
                      : targetLanguage()?.name
                  }}
                </span>

        

                <input
                  class="form-control"
                  type="text"
                  [disabled]="isAnythingLoading()"
                  [formControlName]="headingControl.key"
                  [placeholder]="headingControl.type === 'target' && sourceLanguage() && allTargetLanguageFormControlsAreNull() && deliverableForm.get(headingControl.key).disabled ? ('contributions.locked_awaiting_translation' | transloco) : ('content_management.write_text' | transloco)"
                />

                <div
                  *ngIf="
                    deliverableForm.get(headingControl.key)?.errors
                      ?.maxlength &&
                    deliverableForm.get(headingControl.key)?.dirty
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.too_long') }}
                </div>

                <div
                  *ngIf="
                    deliverableForm.get(headingControl.key)?.errors
                      ?.minlength &&
                    deliverableForm.get(headingControl.key)?.touched
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.too_short') }}
                </div>

                <div
                  *ngIf="
                    deliverableForm.get(headingControl.key)?.errors?.required &&
                    deliverableForm.get(headingControl.key)?.dirty
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.required') }}
                </div>
              </div>
              } }
            </div>
            <div class="input-self">
              <label class="form-label h3 fw-bold">
                {{ t('content_management.main_text') }}
              </label>
              <p class="mb-2 form-text" *transloco="let t; scope: 'contributions'">
                {{ t('contributions.insight.main_text_help') }}
              </p>

              @for(mainTextControl of getTranslatableFormFieldControls('mainText'); track
              mainTextControl){
                
                @if(deliverableForm.get(mainTextControl.key)){


                  <!-- 
                  DEBUGGING
                  <p><b>getSignalValue(mainTextControl.type+'LanguageIsCharacterBased'):</b> {{getSignalValue(mainTextControl.type+'LanguageIsCharacterBased')}}</p>
                  <p><b>editorCharacters[mainTextControl.key]:</b> {{editorCharacters[mainTextControl.key]}}</p>
                  <p><b>translatableFieldRequirements()[mainTextControl.key]?.characters_max_warning:</b> {{translatableFieldRequirements()[mainTextControl.key]?.characters_max_warning}}</p>
                  <p><b>getMax100((editorCharacters[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.characters_max_warning)*100):</b> {{getMax100((editorCharacters[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.characters_max_warning)*100)}}</p>
                  <hr class="my-4">
                  <p><b>editorWords[mainTextControl.key]:</b> {{editorWords[mainTextControl.key]}}</p>
                  <p><b>translatableFieldRequirements()[mainTextControl.key]?.words_max_warning:</b> <span class="text-danger font-weight-bold">{{translatableFieldRequirements()[mainTextControl.key]?.words_max_warning}}</span></p>
                  <p><b>getMax100((editorWords[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.words_max_warning)*100):</b> {{getMax100((editorWords[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.words_max_warning)*100)}}</p> -->

              <div
                class="input-group textarea-control mb-3"
                [ngClass]="{
                  'ng-invalid':
                    deliverableForm.get(mainTextControl.key)?.invalid &&
                    (deliverableForm.get(mainTextControl.key)?.touched ||
                      deliverableForm.get(mainTextControl.key)?.dirty),
                  'ng-valid':
                    deliverableForm.get(mainTextControl.key)?.valid &&
                    deliverableForm.get(mainTextControl.key)?.touched &&
                    deliverableForm.get(mainTextControl.key)?.dirty
                }"
              >
                <span class="input-group-text stacked-form-control-header">
                  <span>
                    {{
                      mainTextControl.type === 'source'
                        ? sourceLanguage()?.name
                        : targetLanguage()?.name
                    }}
                  </span>
                  
                  
                  
                  @if(getSignalValue(mainTextControl.type+'LanguageIsCharacterBased')){
                    <span>
                      <!-- DEBUGGING
                      r:{{translatableFieldRequirements()[mainTextControl.key].words_max_required}} /// w:{{translatableFieldRequirements()[mainTextControl.key].words_max_warning}} /// {{getMax100((editorCharacters[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key].characters_max_warning)*100)+'%'}} ////  -->
                      {{editorCharacters[mainTextControl.key]}}
                    </span>


                    <div class="progress"
                    [style]="'--color: var(--traffic-light-200); --progress-percent: '+getMax100((editorCharacters[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.characters_max_warning)*100)+'%'"
                  >
                  <div class="progress-indicator bg-warning"></div>
                    <!-- The following code will apply traffic light colour styles -->
                    <!-- <div class="progress-indicator" [ngClass]="(editorCharacters[mainTextControl.key] > translatableFieldRequirements()[mainTextControl.key].characters_max_warning) || (editorCharacters[mainTextControl.key] < translatableFieldRequirements()[mainTextControl.key].characters_min_warning) ? 'traffic-light-1' : 'traffic-light-6'"></div> -->
                  </div>

                  } @else {

                    <span>
                      <!-- DEBUGGING
                       r:{{translatableFieldRequirements()[mainTextControl.key].words_max_required}} /// w:{{translatableFieldRequirements()[mainTextControl.key].words_max_warning}} /// {{getMax100((editorWords[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key].words_max_warning)*100)+'%'}} ////  -->
                      {{editorWords[mainTextControl.key]}}
                    </span>


                    <div class="progress"
                    [style]="'--color: var(--traffic-light-200); --progress-percent: '+getMax100((editorWords[mainTextControl.key]/translatableFieldRequirements()[mainTextControl.key]?.words_max_warning)*100)+'%'"
                  >
                    <div class="progress-indicator bg-warning"></div>
                    <!-- The following code will apply traffic light colour styles -->
                    <!-- <div class="progress-indicator" [ngClass]="(editorWords[mainTextControl.key] > translatableFieldRequirements()[mainTextControl.key].words_max_warning) || (editorWords[mainTextControl.key] < translatableFieldRequirements()[mainTextControl.key].words_min_warning) ? 'traffic-light-1' : 'traffic-light-6'"></div> -->
                  </div>

                  }

                </span>
                <!-- Note, by default, the rich text editor will NOT display any placeholder when it is disabled. The following is a hack until we implement a custom solution to show placeholders in the disabled rich text editor -->
                 @if(deliverableForm.get(mainTextControl.key)?.enabled){
                   <multisite-form-control-rich-text-editor
                     [formControlName]="mainTextControl.key"
                     [defaultEditorDataHtml]="'Write <b>cultural text</b> here'"
                     [defaultEditorDataMarkdown]="'Writing **cultural text** here'"
                     (words)="showWords($event, mainTextControl.key)"
                     (characters)="showCharacters($event, mainTextControl.key)"
                     [placeholder]="'content_management.write_text' | transloco"
                     [ngClass]="'w-100'"
                   ></multisite-form-control-rich-text-editor>
                 } @else {
                  <textarea
                    class="form-control"
                    [disabled]="isAnythingLoading()"
                    [formControlName]="mainTextControl.key"
                    [placeholder]="'contributions.locked_awaiting_translation' | transloco : {scope: 'contributions'}">
                  </textarea>
                 }

                <div
                  *ngIf="
                    deliverableForm.get(mainTextControl.key)?.errors
                      ?.maxlength &&
                    deliverableForm.get(mainTextControl.key)?.dirty
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.too_long') }}
                </div>

                <div
                  *ngIf="
                    deliverableForm.get(mainTextControl.key)?.errors
                      ?.minlength &&
                    deliverableForm.get(mainTextControl.key)?.touched
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.too_short') }}
                </div>

                <div
                  *ngIf="
                    deliverableForm.get(mainTextControl.key)?.errors
                      ?.required &&
                    deliverableForm.get(mainTextControl.key)?.dirty
                  "
                  class="invalid-feedback"
                >
                  {{ t('common.required') }}
                </div>
              </div>
              }
            }
            </div>

            <div class="button-group align-self-end">
              @if(combinedLoadingObject().update || combinedLoadingObject().translate || combinedLoadingObject().submit)
                {
                  <button class="btn btn-link p-0 my-0">
                    <multisite-loader-globe-animation-detail 
                    [width]="40"
                    [height]="40"
                   ></multisite-loader-globe-animation-detail>
                  </button>
                }
              @if(sourceLanguage()){
                <button
                  class="btn btn-secondary"
                  [disabled]="!noSourceLanguageFormControlsAreNull() || isAnythingLoading() || deliveryLocked()"
                  (click)="sendForTranslation()"
                >
                  <span *transloco="let t">
                    {{ t('content_management.translate') }}
                  </span>
                </button>
              }
              <button
                class="btn btn-secondary"
                (click)="save('update')"
                [disabled]="isAnythingLoading() || deliveryLocked()"
              >
                <span *transloco="let t">{{ t('common.save') }}</span>
              </button>
              <button
                (click)="save('submit')"
                [disabled]="!deliverableForm.valid || isAnythingLoading() || deliveryLocked()"
                class="btn btn-primary"
                type="button"
              >
                {{ t('common.submit') }}
              </button>
            </div>
            @if(error()){
              <alert [type]="'warning'" [dismissOnTimeout]="9000" class="my-3">
                <strong>{{ t('common.problem')! }}</strong><br>
                <p >{{ errorObject() ? t(errorObject()?.message) : t(errorString()) }}</p>
              </alert>
            }

            
              <!-- <h5>Debugging</h5>
              <p>
              <b>deliverableForm.get('heading')?.enabled</b>: {{deliverableForm.get('heading')?.enabled}}<br>
              <b>deliverableForm.get('mainText')?.enabled</b>: {{deliverableForm.get('mainText')?.enabled}}<br>
              <b>!noSourceLanguageFormControlsAreNull()</b>: {{!noSourceLanguageFormControlsAreNull()}}<br>
              <b>deliveryLocked()</b>: {{deliveryLocked()}}<br>
              <b>isAnythingLoading()</b>: {{isAnythingLoading()}}<br>
              <b>deliverableTargetLanguageTitleFormControlsIsNull()</b>: {{deliverableTargetLanguageTitleFormControlsIsNull()}}<br>
              <b>allTargetLanguageFormControlsAreNull()</b>: {{allTargetLanguageFormControlsAreNull()}}<br>
              <b>deliverableForm.errors</b>: {{deliverableForm.errors | json}}<br>
              <b>deliverableForm.get('mainText')?.errors</b>: {{deliverableForm.get('mainText')?.errors | json}}<br>
              <b>deliverableForm.get('heading')?.errors</b>: {{deliverableForm.get('heading')?.errors | json}}<br>
              <b>deliverableForm.get('mainText_translation_source')?.errors</b>: {{deliverableForm.get('mainText_translation_source')?.errors | json}}<br>
              <b>deliverableForm.get('heading_translation_source')?.errors</b>: {{deliverableForm.get('heading_translation_source')?.errors | json}}<br>
              <b>LoadingObject</b>: {{loadingObject() | json}}<br>
              <b>combinedLoadingObject</b>: {{combinedLoadingObject() | json}}<br>
            </p> -->
              
          </form>
          }
        </div>
      </div>
    </div>
  </div>