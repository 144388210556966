import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public getCommonErrorMessageTranslationKey(backendErrorMessage:string): string | null {
    let returnMessage = null;

    if(!backendErrorMessage){ 
      return returnMessage;
    } else if(backendErrorMessage.includes('-must be registered-')){
      returnMessage = 'error.must_be_registered';
    } else if(backendErrorMessage.includes('Already has the requested status.')){
      returnMessage = 'error.no_action_aready_has_status';
    } else if(backendErrorMessage.includes('Could not complete request. Try again or contact support.')){
      returnMessage = 'error.not_done_try_again';
    } else if(backendErrorMessage.includes('The data given was invalid.')){
      returnMessage = 'error.data_invalid';
    } else if(backendErrorMessage.includes('This action is unauthorised.')){
      returnMessage = 'error.permissions_lacking';
    } else if(backendErrorMessage.includes('The requested action is not allowed.')){
      returnMessage = 'error.not_allowed';
    } else if(backendErrorMessage.includes('Not found.')){
      returnMessage = 'common.not_found';
    }
    return returnMessage;
  }

}
