import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfessionalHomeComponent } from './professional-home/professional-home.component';
import { ProfessionalContributionsParentComponent } from './contributions-parent/contributions-parent.component';
import { ContributionManagementComponent } from './contribution-management/contribution-management.component';

const routes: Routes = [
  {
    path: '',
    component: ProfessionalHomeComponent,
    children: [
      { path: '', redirectTo: 'contributions', pathMatch: 'full' },
      {
        path: 'contributions',
        data: { label: 'Consent', translationKey: 'content.contributions', labelSource: null},
        children: [
          {
            path: '',
            component: ProfessionalContributionsParentComponent,
          },
          {
            path: ':id',
            data: { label: 'Contribution', translationKey: 'content.contribution', labelSource: 'titleService'},
            component: ContributionManagementComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfessionalRoutingModule { }
