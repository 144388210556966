@if(anonymityForm){
  <form [formGroup]="anonymityForm">
    <div class="d-flex justify-content-between">
      <div class="form-group">
        <!-- <label *transloco="let t; scope: 'contributions'">{{t('contributions.anonymity.title')}}</label> -->
        @for(availableLevel of levels; track availableLevel){
            
          <div class="form-check form-check-inline">
            
            <label class="form-check-label" *transloco="let t; scope: 'contributions'">
              <input 
              class="form-check-input" 
              type="radio" 
              [value]="availableLevel"
              [disabled]="disabled() || loading()"
              formControlName="level">
              {{t('contributions.anonymity.'+availableLevel)}}
            </label>
          </div>
                  
        }
      </div>

      <div *transloco="let t">
        <button [disabled]="disabled() || loading()" (click)="doUpdate()" class="btn btn-primary">{{t('common.update')}}</button>
        @if(loading()){
          <span>
            <span class="mt-4 fa-solid fa-arrow-rotate-right fa-spin fa-xl"></span>
          </span>
        } @else {

          <button [disabled]="disabled() || loading() || anonymityForm.get('level')?.value === contributionAnonymity()" (click)="reset()" class="btn btn-link">{{t('common.cancel')}}</button>
        } 
      </div>

    </div>
  </form>

  <p class="text-muted" *transloco="let t; scope:'contributions'">
    {{(anonymityForm.get('level')?.value === 0 || anonymityForm.get('level')?.value > 0) ? t('contributions.anonymity.'+anonymityForm.get('level')?.value+'_help') : ''}}
    {{(anonymityForm.get('level')?.value > 10) ? t('contributions.anonymity.plus_lower_level_details') : ''}}
  </p>
}