import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ContributionAnonymityLevelFormComponent } from './anonymity-level-form.component';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

@NgModule({
    declarations: [ContributionAnonymityLevelFormComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        ReactiveFormsModule
    ],
    exports: [ContributionAnonymityLevelFormComponent],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'contributions' }],
})
export class ContributionAnonymityLevelFormModule { }