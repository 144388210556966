import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { AuthService, CloudinaryMediaAsset, ContentItem, Culture, DataProcessingService, Experience, LanguageService, MediaService, MultipleChoiceQuestion, Topic } from '@frontend/common';  // Note, for some reason the AuthService import causes errors when imported from a relative path e.g. '../auth';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Survey } from './survey.model';
import { TrackingService } from '../tracking/tracking.service';
import { ErrorService } from '../error';

interface QuestionsByModelResponse {
  data : any[]; // could be multiple choice question or something else
}
interface SurveysListingResponse {
  data : Survey[]
}

interface SingleQuestionResponseResponse {
  data: {
    'survey_id': number,
    'survey_correction': string,
    'corrections': any[],
    'progress': number
  }
}
export interface LanguagePreferenceOptions {
    first_person_form_1?: string; //'   => 'Je suis un traducteur compétent',
    first_person_form_2?: string; //'   => 'Je suis une traductrice compétente',
    third_person_form_1?: string; //'   => 'Il est un traducteur compétent',
    third_person_form_2?: string; //'   => 'Elle est une traductrice compétente',
    third_person_plural_form_1?: string; //' => 'Ils traduisent avec compétence',
    third_person_plural_form_2?: string; //' => 'Elles traduisent avec compétence',

}
export interface LanguagePreferences {
  [key:string]:string // {'fr':'first_person_form_2','de':'first_person_form_2'}
}

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  questionsByModel : any[] = [];
  singleSurveys : Survey[] = []; // for caching and retrieving single survey when we have not requested the full collection of surveys by category and type
  surveysByCategoryAndType : { // for caching and retrieving the full collection of surveys by category and type, not for caching a single Survey
    [key:string]:{[key:string]:Survey[]}
    // child property: category, grandchild property: type
    // pages are always in an array
    /* 'privacy':{'pages':[arrayOfPagesWithoutType],'notice':[arrayContaningOneSinglePage]} */
  } = {};
  activeLanguageSubscription: Subscription;
  languagePreferenceOptionsArray : {languageKey:string,options:LanguagePreferenceOptions}[] = [];
  languagePreferencesBySurvey : {survey_id:number,preferences:LanguagePreferences}[] = [];
  public surveyCompleteAlertSubject: Subject<string>
  public surveyCompleteAlert: Observable<string>

  constructor(
    private http: HttpClient,
    private mediaService: MediaService,
    private trackingService : TrackingService,
    private dataProcessingService : DataProcessingService,
    private authService : AuthService,
    private languageService: LanguageService,
    private errorService: ErrorService,
  ) { 
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
    this.surveyCompleteAlertSubject = new Subject<string>();
    this.surveyCompleteAlert = this.surveyCompleteAlertSubject.asObservable();
  }

  clearTranslations() {
    this.questionsByModel = [];
    this.singleSurveys = [];
    this.surveysByCategoryAndType = {};
  }
  clearData() {
    this.clearLanguagePreferences();
    this.clearTranslations();
  }
  clearLanguagePreferences() {
    this.languagePreferencesBySurvey = [];
  }

  transformMultipleChoiceQuestion (question){
    if (question.media){
      question.media = Object.assign(question.media, this.mediaService.setupCloudinaryImageMediaUrls(question.media));
    };
    return question;
  };
  transformQuestion (question){
    if (question.class === 'MultipleChoiceQuestion'){
      return this.transformMultipleChoiceQuestion(question);
    };
    return question;
  };
  transformMultipleChoiceQuestions (questions){
    let transformedQuestions = [];
    questions.forEach(q=>{
      transformedQuestions.push(this.transformMultipleChoiceQuestion(q));
    });
    return transformedQuestions;
  }
  transformSurvey (surveyResponse) : Survey {
    let surveyWithoutExtraData = {...surveyResponse};
    delete surveyWithoutExtraData.metaTexts;
    let survey : Survey = surveyWithoutExtraData;
    if (surveyResponse.media?.length){
      let additional_media : CloudinaryMediaAsset[] = [];
      let media : CloudinaryMediaAsset;
      surveyResponse.media.forEach(item => {
        if (item.category==='thumbnail' && item.type ==='main'){
          media = Object.assign(item, this.mediaService.setupCloudinaryImageMediaUrls(item));
        } else {
          additional_media.push(Object.assign(item, this.mediaService.setupCloudinaryImageMediaUrls(item)));
        } 
      });
      survey.media = media;
      survey.additional_media = additional_media;
    };
    if (surveyResponse.metaTexts?.length){
      survey.subheading = surveyResponse.metaTexts.find(m=>m.category==='profile' && m.type==='general')?.s;
      survey.description_short = surveyResponse.metaTexts.find(m=>m.category==='profile' && m.type==='general')?.m;
      survey.instructions = surveyResponse.metaTexts.filter(m=>m.category==='instruction');
    }
    return survey;
  };
  transformSurveys (surveys){
    let transformedSurveys = [];
    surveys.forEach(s=>{
      transformedSurveys.push(this.transformSurvey(s));
    });
    return transformedSurveys;
  }
  cacheQuestionsByModel (questions : any, modelNameSingular : string, model_slug: string, model_id: number, survey_id : number, backendClassNameWithPath: string){
    let cachedIndex = this.getCachedQuestionsByModelIndex(modelNameSingular, backendClassNameWithPath, model_id, survey_id);
    if (cachedIndex >-1){
      this.questionsByModel[cachedIndex] = questions;
    } else {
      this.questionsByModel.push(questions);
    }
  };
  getCachedQuestionsByModelIndex (modelNameSingular : string, backendClassNameWithPath: string, model_id : number, survey_id : number) : number {
    if (modelNameSingular && !backendClassNameWithPath){
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }
    let foundIndex : number = this.questionsByModel.findIndex(questions => questions.pivot_id === model_id && questions.pivot_type === backendClassNameWithPath);
    if (foundIndex >-1) {
      return foundIndex;
    };
  };

  getQuestionsByModel(modelNameSingular : string, model_slug: string, model_id: number, survey_id : number, backendClassNameWithPath: string){

    if (modelNameSingular){
      modelNameSingular =  modelNameSingular.toLowerCase();
    } else {
      throw new Error("You must provide the class name");      
    }
    if (modelNameSingular && !backendClassNameWithPath){ // TODO - illogical to check for modelNameSingular. We have just checked
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }
    let cachedQuestionsIndex = this.questionsByModel[this.getCachedQuestionsByModelIndex (modelNameSingular, backendClassNameWithPath, model_id, survey_id)];
    
    if (cachedQuestionsIndex){
      return of(this.questionsByModel[cachedQuestionsIndex]);
    };

    let identifier = model_id ? model_id : model_slug;
    let url = 'api/v1/questions/class/'+modelNameSingular+'/'+identifier;
    if(survey_id){
      url = url+'?survey_id='+survey_id;
    }
    /* for example 
      api/v1/questions/class/lesson/libero-quibusdam-iure-quia-similique-iusto7555?survey_id=13
    */
    return this.http.get<{data: QuestionsByModelResponse}>(url)
        .pipe(
          map(response =>{
            if (response ){
              let questionsFromBackend = this.transformMultipleChoiceQuestions(response.data);
              //
              this.cacheQuestionsByModel (questionsFromBackend,modelNameSingular, model_slug, model_id, survey_id, backendClassNameWithPath)
              return questionsFromBackend;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  getNextQuestionBySurvey(slug: string){

    if (!slug){
      throw new Error("You must provide the survey name");      
    }
    let url = 'api/v1/survey/questions/next/'+slug;

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    /* for example 
      api/v1/survey/questions/next/libero-quibusdam-iure-quia-similique-iusto7555?guest_uuid=xxxxxxx-xxxxxxx-xxxxxxx-xxxxxxxxx
    */
    return this.http.get<{data: any, message: string}>(url)
        .pipe(
          map(response =>{
            if (response?.data ){
              return {'question':this.transformQuestion(response.data)};
            } else {
              return {'question':null, 'message':response?.message};
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  getImage (surveyMedia : CloudinaryMediaAsset[], type : string){
    if (!surveyMedia?.length){ return null ;};
    if (type === 'thumbnail'){
      return surveyMedia.find(m=>m.category==='thumbnail' && m.type==='main');
    }
  }
  getCachedSurvey (slug: string){
    return this.singleSurveys.find(s=>s.slug === slug);
  }
  getCachedLanguagePreferenceOptions (languageKey: string){
    return this.languagePreferenceOptionsArray.find(lpo=>lpo.languageKey === languageKey)?.options;
  }
  cacheLanguagePreferenceOptions (languagePreferenceOptions: LanguagePreferenceOptions, languageKey: string){
    let cachedIndex = this.languagePreferenceOptionsArray.findIndex(lpo=>lpo.languageKey === languageKey);
    if (cachedIndex >-1){
      this.languagePreferenceOptionsArray[cachedIndex]['options'] = languagePreferenceOptions;
    } else {
      this.languagePreferenceOptionsArray.push({languageKey:languageKey,options:languagePreferenceOptions});
    }
  };
  cacheSingleSurveys (surveys: Survey[]){
    surveys.forEach(c=>{
      this.cacheSurvey(c);
    });
  };
  cacheSurveyCollection (surveys: Survey[], category: string, type: string){
    if (!surveys || !category || !type){return;};
    if (!this.surveysByCategoryAndType[category]){
      this.surveysByCategoryAndType[category] = {};
    }
    this.surveysByCategoryAndType[category][type] = surveys;
  };
  cacheSurvey (survey: Survey){
    let cachedSurveyIndex = this.singleSurveys.findIndex(c=>c.id === survey.id);
    if (cachedSurveyIndex >-1){
      this.singleSurveys[cachedSurveyIndex] = survey;
    } else {
      this.singleSurveys.push(survey);
    }
  };
  insertCulturesIntoSurvey(survey : Survey, cultures:Culture[]){
    survey.cultures = cultures.filter(c=>survey.culture_slugs.includes(c.slug));
    return survey;
  }
  insertCulturesIntoCachedSurveys(cultures:Culture[]){
    if(!cultures?.length){return;};
    for (let category in this.surveysByCategoryAndType){
      for (let type in this.surveysByCategoryAndType[category]){
        this.surveysByCategoryAndType[category][type] = this.surveysByCategoryAndType[category][type].map(survey=> this.insertCulturesIntoSurvey(survey,cultures));
      }  
    }
  }
  insertTopicsIntoSurvey(survey : Survey, topics:Topic[]){
    survey.topics = topics.filter(c=>survey.topic_slugs.includes(c.slug));
    return survey;
  }
  insertTopicsIntoCachedSurveys(topics:Topic[]){
    if(!topics?.length){return;};
    for (let category in this.surveysByCategoryAndType){
      for (let type in this.surveysByCategoryAndType[category]){
        this.surveysByCategoryAndType[category][type] = this.surveysByCategoryAndType[category][type].map(survey=> this.insertTopicsIntoSurvey(survey,topics));
      }  
    }
  }
  getSurveys (category: string, type : string, additionalFilters : {[key:string]:string}, freshFromServer: boolean){ // because of filtering, we will always get freshFromServer

    let requestingCollection : boolean = (category && type && (!additionalFilters || Object.keys(additionalFilters).length === 0));

    let results;

    if (category && type && this.surveysByCategoryAndType[category]?.[type]){
      results = this.surveysByCategoryAndType[category][type];
    }

    if (results && !freshFromServer){
      if (additionalFilters?.cultures){ // at the moment we only support cultures as an additional filter
        return of(results.filter(s=>s.culture_slugs.some(slug=>additionalFilters.cultures.includes(slug)))); 
      } else {
        return of(results);
      }
    };
    let url = 'api/v1/surveys/?category='+category+'&type='+type;
    let queryString = '';
    if(additionalFilters){
      queryString = new URLSearchParams(additionalFilters).toString();
      url += '&'+queryString;
    }

    return this.http.get<SurveysListingResponse>(url)
        .pipe(
          map(response =>{
            if (response ){
              let surveys = this.transformSurveys(response.data);
              if(requestingCollection){
                this.cacheSurveyCollection(surveys,category,type);
              }
              this.cacheSingleSurveys(surveys);
              return surveys;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  }
  getSurvey (slug : string, freshFromServer: boolean){
    if (!slug){alert('Check the address');};
    slug = slug.toLowerCase()
    let cachedSurvey : Survey = this.getCachedSurvey(slug);
    if (cachedSurvey && !freshFromServer){
      return of(cachedSurvey);
    };
    
    return this.http.get<{data: Survey[]}>('api/v1/surveys/slug/'+slug)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let survey : Survey =  this.transformSurvey(response.data)
              this.cacheSurvey(survey);
              return survey;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  getLanguagePreferenceOptions (languageKey:string){
    if (!languageKey){alert('Check the language');};
    let languagePreferenceOptions : LanguagePreferenceOptions = this.getCachedLanguagePreferenceOptions(languageKey);
    if (languagePreferenceOptions){
      return of(languagePreferenceOptions);
    };
    
    return this.http.get<{data: LanguagePreferenceOptions, locale: string}>('api/v1/survey/language-preference-options')
        .pipe(
          map(response =>{
            if (response && response.data ){
              let languagePreferenceOptions : LanguagePreferenceOptions = response.data;
              this.cacheLanguagePreferenceOptions(languagePreferenceOptions,response.locale)
              return languagePreferenceOptions;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  setLanguagePreferences (survey_id:number,grammatical_form:string){
    if (!survey_id || !grammatical_form){alert('Check the parameters');};
    let url = 'api/v1/survey/language-preferences/'+survey_id;
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.post<{data: LanguagePreferences}>(url,{form:grammatical_form})
        .pipe(
          map(response =>{
            if (response?.data ){
              this.cacheLanguagePreferences(survey_id,response.data);
              return response.data;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  cacheLanguagePreferences (survey_id: number, languagePreferences: LanguagePreferences){
    let cachedIndex = this.languagePreferencesBySurvey.findIndex(lpbs=>lpbs.survey_id === survey_id);
    if (cachedIndex >-1){
      this.languagePreferencesBySurvey[cachedIndex] = {survey_id:survey_id,preferences:languagePreferences};
    } else {
      this.languagePreferencesBySurvey.push({survey_id:survey_id,preferences:languagePreferences});
    }
  };
  getCachedLanguagePreferences (survey_id:number){
    return this.languagePreferencesBySurvey.find(lpbs=>lpbs.survey_id === survey_id)?.preferences;
  }
  getLanguagePreferences (survey_id:number){
    if (!survey_id){alert('Check the survey id');};
    let languagePreferences = this.getCachedLanguagePreferences(survey_id);
    if (languagePreferences){
      return of(languagePreferences);
    };
    let url = 'api/v1/survey/language-preferences/'+survey_id;
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.get<{data: LanguagePreferences}>(url)
        .pipe(
          map(response =>{
            if (response?.data ){
              this.cacheLanguagePreferences(survey_id,response.data);
            };
            return response.data;
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  submitSingleQuestionResponse (survey_id : number, question_id : number, questionResponse : any){
    if (!survey_id && !question_id){
      throw new Error("You must identify the survey and the question");
    }
    let url = 'api/v1/questions/response/'+survey_id+'/'+question_id;

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.post<SingleQuestionResponseResponse>(
      url, {response: questionResponse})
        .pipe(
          map(response =>{
            if (response ){
              return response.data;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  }
  getRelatedExperiences (slug : string, snapshot_id: number, freshFromServer : boolean = false, withArchivedExperiences : boolean = false){
    if (!slug){alert('Check the address');};
    slug = slug.toLowerCase()
    let cachedSurvey : Survey = this.getCachedSurvey(slug);
    let relatedExperiences : Experience[];
    if (cachedSurvey?.id && !freshFromServer){
      relatedExperiences =  this.trackingService.getCachedExperienceMultiple('survey', null, cachedSurvey.id, null,withArchivedExperiences).filter(e=>e.snapshot_id===snapshot_id);
    };
    let relatedExperiencesIncludeSomeArchivedExperiences : boolean; // TODO - this is not a good approach!! If we need to view the archive and it does not exist on the backend, we have to call this every time to discover the non-existence

    if (relatedExperiences?.length){
      relatedExperiencesIncludeSomeArchivedExperiences = !!relatedExperiences.find(e=>e.archive_id);
    };
    if (relatedExperiences?.length && !this.trackingService.isTheOnlyExperienceProgress (relatedExperiences) && (!withArchivedExperiences || (withArchivedExperiences && relatedExperiencesIncludeSomeArchivedExperiences))){
      return of(relatedExperiences);
    };

    let params = new URLSearchParams();
    let url = 'api/v1/survey/experiences/'+slug+'?';
    
    if (snapshot_id){
      params.append('snapshot_id',snapshot_id.toString())
    };
    if (withArchivedExperiences){
      params.append('with_archive','true')
    };

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      params.append('guest_uuid',guest.uuid)
    };
    if (!user && !guest){
      throw 'Log in or create guest';
    };
    
    return this.http.get<{relatedExperiences: Experience[]}>(url+params.toString())
        .pipe(
          map(response =>{
            if (response && response.relatedExperiences ){
              this.trackingService.cacheMultipleExperiences(response.relatedExperiences);
              return response.relatedExperiences;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  archive (slug : string, snapshot_id : number){
    if (!slug){alert('Check the address')};
    let user = this.authService.user.getValue();
    if (!user){
      throw 'Login required';
    };
    if (snapshot_id){
      throw 'Cannot archive snapshotables.'; // Cancel or end the snapshot instead
    };
    slug = slug.toLowerCase();
    
    return this.http.get<{relatedExperiences: Experience[]}>('api/v1/survey/send-to-archive/'+slug)
        .pipe(
          map(response =>{
            if (response && response.relatedExperiences ){ // the backend will return all the experiences for this survey, including all archives
              return this.trackingService.cacheMultipleExperiences(response.relatedExperiences);
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        );
  };
  getProgressValueOfSurvey(experiences: Experience[], survey : Survey){ // currently this only works for current (not archived) surveys
    if (!experiences?.length || !survey){return};
    return experiences.find(e=>e.experienceable_type === this.dataProcessingService.convertModelNameToBackendClass('survey') && e.experienceable_id === survey.id && e.experience_verb === 'progressed' && e.archive_id == null)?.experience_value;
  };
  getBooleanStatusOfSurvey(experiences: Experience[], survey_id:number, experience_verb: string){ // currently this only works for current (not archived) surveys
    if (!experiences?.length){return};
    return Boolean (experiences.find(e=>e.experienceable_type === this.dataProcessingService.convertModelNameToBackendClass('survey') && e.experienceable_id === survey_id && e.experience_verb === experience_verb && e.experience_value == 1 && e.archive_id == null));
  };
  convertSurveysToContentItems (surveys: Survey[]) : ContentItem[] {
    let content : ContentItem[] = [];
    if (surveys?.length){
      surveys.forEach(survey => {
        content.push(this.convertSurveyToContentItem(survey));
      });
    }
    return content;
  }
  convertSurveyToContentItem (survey: Survey): ContentItem {
    if (!survey) {return null;};
    let contentItem = new ContentItem (
      survey.id,
      null,// survey.name, // we want the name to appear in the subtitle
      null,
      survey.name, // survey.subheading,
      null,
      survey.description_short,
      null,
      null,
      survey.slug,
      null,null,
      survey.media, null,
      null,survey.rating,null,
      null,
      survey.tags,
      null,
      {completions: survey.completions}
      );
      return contentItem;
  }
  convertMultipleChoiceQuestionToContentItem (q: MultipleChoiceQuestion): ContentItem {
    if (!q) {return null;};
    let contentItem = new ContentItem (
      q.id,
      q.text,// q.name, // we want the name to appear in the subtitle
      q.label,
      null, // q.subheading,
      q.context,
      q.help,
      null,
      null,
      null,
      null,
      null,
      q.media,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      {}
      );
      return contentItem;
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    const message = errorResponse.error.message;
    const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
    if(standardErrorMessageTranslationKey){
      errorMessage = standardErrorMessageTranslationKey;
    } else if(message.includes('Survey not found')){
      errorMessage = 'common.not_found';
    } else if(message.includes('You must start the survey before you can set Language preferences.')){
      errorMessage = 'error.report_code_support';
      errorResponse.error.meta ? errorResponse.error.meta['error_code']=1 : errorResponse.error.meta = {error_code:1};
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);

  }

}
