import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../auth';
import { AvailableLanguage, LanguageService } from '../../language';
import { DataProcessingService, WindowService } from '../../utilities';
import { ContributionsService } from '../../contributions';
import { PaginatedContributions } from '../../contributions/paginated-contributions.model';
import { QueryParamsService, SortCriterion } from '../../search';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentItem, PaginatedContentItems } from '../../content';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'contributions-parent',
  templateUrl: './contributions-parent.component.html',
  styleUrls: ['./contributions-parent.component.scss']
})
export class ProfessionalContributionsParentComponent implements OnInit, OnDestroy {

  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  subscriptions = signal<Subscription[]>([]);
  loadingObject = signal <{[key:string]:boolean}>({}); // .contributions
  queryParams = signal<{[key:string]:string | string[]}>({}) // 'cultures=france,germany'
  maxQueryStringLength : number = 50;
  paginatedContributions = signal<PaginatedContributions|undefined>(undefined);
  paginatedContentItems = signal<PaginatedContentItems|undefined>(undefined);
  sort_criteria : SortCriterion[];
  available_categories = signal<string[]>([]);
  available_anonymity_levels = signal<number[]>([]);
  available_anonymity_level_strings = computed<string[]>(()=>{
    return this.available_anonymity_levels().map(al => al.toString());
  });
  anonymity_level_form_current_value = signal<number|undefined>(-1); // We use this for resetting the form
  contributable_types = signal<string[]>([]);
  selected_types = computed<string[]>(() => {
    return this.queryParams()['contributable_types'] ? this.queryParams()['contributable_types'] as string[] : [];
  });
  selected_categories = computed<string[]>(() => {
    return this.queryParams()['category'] ? this.queryParams()['category'] as string[] : [];
  });
  selected_anonymity_levels = computed<string[]>(() => {
    return this.queryParams()['anonymity'] ? this.queryParams()['anonymity'] as string[] : [];
  });
  selected_custom_params = computed<string[]>(() => {
    return [...this.selected_types(),...this.selected_categories(),...this.selected_anonymity_levels()];
  });
  default_items_per_page = signal<number>(20);
  call_to_action = signal<string|undefined>(undefined);
  totalLabelTranslation = signal<string|undefined>(undefined);
  total_contributions_label = computed<string>(() => {
    return this.totalLabelTranslation() + (this.selected_custom_params().length ? ' (' + this.translocoService.translate('common.filtered') +')' : '');
  });
  collapseObject = signal<{[key:string]:boolean}>({});
  user = signal<User|undefined>(undefined);
  error = signal<string|{message:string,meta:any}|undefined>(undefined);

  constructor(
    private languageService: LanguageService,
    private contributionsService: ContributionsService,
    private translocoService: TranslocoService,
    private dataProcessingService: DataProcessingService,
    private windowService : WindowService,
    private route : ActivatedRoute,
    private router : Router,
    private queryParamsService : QueryParamsService,
  ) {
    this.available_categories.set(this.contributionsService.contribution_categories);
    this.available_anonymity_levels.set(this.contributionsService.contribution_anonymity_levels);
    this.contributable_types.set(this.contributionsService.contributable_types);
  }

  setLoadingStatus(key: string, value: boolean){
    // So we can detect deep changes in the object
    this.loadingObject.set({...this.loadingObject(),[key]:value});
  }
  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }
  toggleCollapse(key: string){
    this.collapseObject()[key] = !this.collapseObject()[key];
  }
  setCollapse(key: string, value: boolean){
    this.collapseObject()[key] = value;
  }
  gotoContribution(contentItem:ContentItem){

    if(!contentItem?.meta?.contribution){
      return;
    }

    this.router.navigate([contentItem.meta.contribution.id], {relativeTo: this.route, queryParams: {}}); 

  }
  truncateText(sourceText : string = '', length : number){
    return this.dataProcessingService.truncateText(sourceText, length);
  }
  isSelected(paramKey: string, paramValue: string): boolean {
    const param = this.queryParams()[paramKey];
    if (param) {
      if (Array.isArray(param)) {
        return param.includes(paramValue);
      } else if (typeof param === 'string') {
        return param.split(',').includes(paramValue);
      }
    }
    return false;
  }
  toggleParam(paramKey: 'contributable_types' | 'category' | 'anonymity', paramValue: string) {
    const add = this.queryParams()[paramKey] ? !this.isSelected(paramKey, paramValue) : true;
    this.queryParams.set(this.queryParamsService.updateQueryParams(paramKey, paramValue, add));
    this.setPage(); // We have to reset the page number when we change the filters
  }
  setPage(page?: number){
    page = page ?? 1;
    const queryParams = { ...this.queryParams(), page: page.toString() };
    queryParams['page'] = page.toString();
    this.queryParams.set(queryParams);
    this.queryParamsService.navigateAfterUpdatingQueryParams(queryParams,false);
    this.getContributions(this.queryParams());
  }

  clearCustomParams() {
    const queryParams = {...this.queryParams()};
    if(queryParams['contributable_types']){
      delete queryParams['contributable_types'];
    }
    if(queryParams['category']){
      delete queryParams['category'];
    }
    if(queryParams['anonymity']){
      delete queryParams['anonymity'];
    }
    this.updateQueryParams(queryParams);
    this.setPage();
  }

  updateQueryParams(queryParams : {[key:string]:string | string[]} = {}){

    queryParams['page'] = this.dataProcessingService.validateQueryParamNumber(queryParams['page'],1).toString();
    queryParams['items_per_page'] = this.dataProcessingService.validateQueryParamNumber(queryParams['items_per_page'],this.default_items_per_page()).toString();

    if(!queryParams['sort_by']){
      queryParams['sort_by'] = 'created_at';
    }
    if(queryParams['sort_order'] !== 'asc'){
      queryParams['sort_order'] = 'desc';
    }

    this.queryParams.set(queryParams);
    this.queryParamsService.navigateAfterUpdatingQueryParams(queryParams,false);

  }
  getContributions(queryParams : {[key:string]:string | string[]} = {}, freshFromServer = false){

    this.setLoadingStatus('paginatedContributions',true);

    // Convert comma-separated strings to arrays
    const filters = queryParams['category'] ? { category: (Array.isArray(queryParams['category']) ? queryParams['category'] : (queryParams['category'] as string).split(',')) } : {};
    if(queryParams['anonymity']){
      filters['anonymity'] = (Array.isArray(queryParams['anonymity']) ? queryParams['anonymity'] : (queryParams['anonymity'] as string).split(',')).map(a => +a);
    }
    const contributable_types = queryParams['contributable_types'] ? (Array.isArray(queryParams['contributable_types']) ? queryParams['contributable_types'] : (queryParams['contributable_types'] as string).split(',')) : [];

    const formattedQueryParams = {...queryParams, contributable_types: contributable_types};

    const paginatedContributionsSubscription = this.contributionsService.getData("myPaginatedContributions", filters, formattedQueryParams, freshFromServer).subscribe(reportData => {
      this.paginatedContributions.set(reportData.data as PaginatedContributions);
      this.paginatedContentItems.set({...this.paginatedContributions(), data:this.contributionsService.convertContributionsWithContributablesToContentItems(this.paginatedContributions().data)});
      this.setLoadingStatus('paginatedContributions',false);
  }, error => {
      this.error = error;
      this.setLoadingStatus('paginatedContributions',false);
    });
  }
  updateAnonymityLevel(level : number){
    const translation = this.translocoService.translate('common.permanent_are_you_sure');
    if (confirm(translation)) {
      this.setLoadingStatus('anonymity',true);
      this.anonymity_level_form_current_value.set(level);
      const updateContributionsSubscription = this.contributionsService.updateAllContributions({anonymity:level}).subscribe(contribution => {
        this.setLoadingStatus('anonymity',false);
        this.anonymity_level_form_current_value.set(-1);
        this.setCollapse('anonymity_more_info',true);
        this.getContributions(this.queryParams(), false); // TODO - we could probably say false here
      }, error => {
        this.error.set(error);
        this.anonymity_level_form_current_value.set(-1);
        this.setLoadingStatus('anonymity',false);
    }
  );
      this.subscriptions().push(updateContributionsSubscription);
    }
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    const queryParams = {...this.route.snapshot.queryParams}; // make the snapshot object mutable
    this.updateQueryParams(queryParams); // alternative way to get params: this.router.routerState.snapshot.root.queryParams;
    this.getContributions(this.queryParams());
    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    this.call_to_action.set(this.translocoService.translate('common.manage'));
    this.totalLabelTranslation.set(this.translocoService.translate('common.total'));
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
        this.activeLanguageObject.set(newActiveLanguage);
        this.getContributions(this.queryParams(), true);
        this.call_to_action.set(this.translocoService.translate('common.manage'));
        this.totalLabelTranslation.set(this.translocoService.translate('common.total'));
      }
    });

    this.subscriptions().push(activeLanguageSubscription);
    this.setCollapse('anonymity_more_info',true);
  }

  ngOnDestroy(): void {
    this.subscriptions().forEach(subscription => subscription.unsubscribe());
  }

}
