import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { Contribution, ContributionsService, Insight } from '../../contributions';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MultipleChoiceQuestion } from '../../surveys';
import { ContentItem, InsightsService, MediaService, Tip } from '../../content';
import { CloudinaryMediaAsset } from '../../models/cloudinary-media-asset.model';
import { DataProcessingService, WindowService } from '../../utilities';
import { PageTitleService } from '../../navigation';

@Component({
  selector: 'multisite-contribution-management',
  templateUrl: './contribution-management.component.html',
  styleUrl: './contribution-management.component.scss'
})
export class ContributionManagementComponent implements OnInit, OnDestroy {

  contribution = signal<Contribution | undefined>(undefined);
  contributable = signal<ContentItem | undefined>(undefined);
  contributableMedia = computed<CloudinaryMediaAsset>(() => {
    return this.contributable()?.media ?? (this.insight()?.media?.[0] ?? null);
  });
  contributions = signal<Contribution[]>([]);
  relatedContributions = computed<Contribution[]>(() => {
    return this.contributions().filter(contribution => contribution.id !== this.contribution().id);
  });
  insight = signal<Insight | undefined>(undefined);
  subscriptions : Subscription[] = [];
  loadingObject = signal <{[key:string]:boolean}>({});
  error = signal<string|{message:string,meta:any}|undefined>(undefined);

  errorString = computed(() => {
    if(this.error()){
      return typeof this.error() === 'string' ? this.error() : (this.error() as {message:string,meta:any}).message;
    }
  });
  errorObject = computed(() => {
    if(this.error()){
      return typeof this.error() === 'object' ? this.error() : null;
    }
  });

  constructor(
    private contributionsService : ContributionsService,
    private route : ActivatedRoute,
    private mediaService : MediaService,
    private dataProcessingService : DataProcessingService,
    private windowService : WindowService,
    private pageTitleService : PageTitleService,
    private insightsService : InsightsService
  ) {
    
  }
  setLoadingStatus(key: string, value: boolean){
    // So we can detect deep changes in the object
    this.loadingObject.set({...this.loadingObject(),[key]:value});
  }
  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }
  gotoTop() {
    this.windowService.goToTop();
  }

  getMediaUrlWithTransformations(media: CloudinaryMediaAsset, transformations: string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(media.file_url, transformations);
  }
  getFlagUrlFromHash(hash:string,transformations : string = '', file_extension: string = '.png'){
    return this.mediaService.getFlagUrlFromHash(hash,transformations,true,file_extension);
  }
  insertCloudinaryTransformationsIntoMediaUrl(url: string, transformations: string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(url, transformations);
  }
  truncateText(sourceText : string = '', length : number){
    return this.dataProcessingService.truncateText(sourceText, length);
  }

  updateAnonymityLevel(level : number, contribution : Contribution){
    this.setLoadingStatus('anonymity',true);
    const updateContributionsSubscription = this.contributionsService.updateContribution(contribution,{anonymity:level}).subscribe(contribution => {
      this.setLoadingStatus('anonymity',false);
      this.contributions.set(this.contributions().map(c => (c.id === contribution.id) ? contribution : c));
    },
    error => {
      this.error.set(error);
      this.setLoadingStatus('anonymity',false);
    });
    this.subscriptions.push(updateContributionsSubscription);
  }
  convertContributableToContentItem(contribution : Contribution){
    return this.contributionsService.convertContributionsWithContributablesToContentItems([contribution]);
  }
  getMyContributionsByContributable(contribution : Contribution){
    const modelName = (this.dataProcessingService.convertBackendClassToModelName(contribution.contributable_type) as 'insight' | 'lesson' | 'course' | 'tip' | 'survey' | 'multiple_choice_question');
    this.setLoadingStatus('contributions',true);
    const contributionsSubscription = this.contributionsService.getMyContributionsByContributable(modelName,contribution.contributable_id).subscribe(contributions => {
    this.setLoadingStatus('contributions',false);
      this.contributions.set(contributions);
    },
    error => this.setLoadingStatus('contributions',false));
    this.subscriptions.push(contributionsSubscription);
  }
  getInsight(id : number){
    this.setLoadingStatus('insight',true);
    const getInsightSubscription = this.insightsService.getInsight(id,false).subscribe(insight => {
      this.insight.set(insight);
      this.contributable.set(this.insightsService.convertInsightToContentItem(insight));
      this.pageTitleService.setTitle(this.contribution().id +' | '+ this.contributable().title);
      this.setLoadingStatus('insight',false);
      this.getMyContributionsByContributable(this.contribution());
    },
    error => {
      this.error.set(error);
      this.setLoadingStatus('insight',false);
    }
  );
    this.subscriptions.push(getInsightSubscription);
  }
  getContribution(id : number){
    this.setLoadingStatus('contribution',true);
    const getContributionSubscription = this.contributionsService.getContribution(id).subscribe(contribution => {
      this.contribution.set(contribution);

      // Show a generic ContentItem until we have fetched the specific model (e.g. Insight) from the backend

      const contributableArray = this.convertContributableToContentItem(contribution);
      if(contributableArray?.[0]){
        this.contributable.set(contributableArray[0]);
        this.pageTitleService.setTitle(this.contribution().id +' | '+ contributableArray[0].title);

      } else {
        this.pageTitleService.setTitle(this.contribution().id);
      }
      this.setLoadingStatus('contribution',false);
      this.getMyContributionsByContributable(this.contribution());
      if('insight' === this.dataProcessingService.convertBackendClassToModelName(contribution.contributable_type)){
        this.getInsight(contribution.contributable_id);
      }
      // this.breadcrumbService.setBreadcrumbFragment({ // This is overridden by the titleService or by the static route data
      //   urlFragment: 'id',
      //   fragmentName: this.contribution().id.toString(),
      // });

    },
    error => {
      this.error.set(error);
      this.setLoadingStatus('contribution',false);
    }
  );
    this.subscriptions.push(getContributionSubscription);
  }

  ngOnInit() {

    const contribution_id = this.route.snapshot.params['id'];

    if(contribution_id){
      this.getContribution(+contribution_id);
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
