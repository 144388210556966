import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfessionalRoutingModule } from './professional-routing.module';
import { VerticalMenuTypeOneModule } from '../navigation';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../loaders';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfessionalHomeComponent } from './professional-home/professional-home.component';
import { AvatarsModule, ComingSoonModule, PaginationModule, PillsModule } from '@frontend/shared';
import { LanguageModule } from '../language';
import { ProfessionalContributionsParentComponent } from './contributions-parent/contributions-parent.component';
import { ScoreHighlightModule } from '../content';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ContributionAnonymityLevelFormModule } from '../contributions';
import { ContributionManagementComponent } from './contribution-management/contribution-management.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    ProfessionalHomeComponent,
    ProfessionalContributionsParentComponent,
    ContributionManagementComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ProfessionalRoutingModule,
    TranslocoModule,
    VerticalMenuTypeOneModule,
    AlertModule,
    LoadersModule,
    ComingSoonModule,
    LanguageModule,
    PillsModule,
    AvatarsModule,
    PaginationModule,
    ScoreHighlightModule,
    CollapseModule,
    ContributionAnonymityLevelFormModule,
  ],
  exports: [
    ProfessionalHomeComponent
  ],
  providers: [
    { provide: TRANSLOCO_SCOPE, useValue: 'contributions' },
  ],

})
export class ProfessionalModule { }
