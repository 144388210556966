import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Subscription, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService, CloudinaryMediaAsset, ContentItem, Contributor, Culture, LanguageService, Lesson, LessonExtraLite, MediaService, MetaText, PlaylistTextItem, PlaylistTextItemGroup, Topic } from '@frontend/common';
import { Link, Tag, Video } from '@frontend/core';
import { Course } from './course.model';
import { Status } from '../tracking/status.model';
import { CourseModule } from './course-module.model';
import { TrackingService } from '../tracking/tracking.service';
import { Experience } from '../tracking/experience.model';
import { DataProcessingService } from '../utilities/data-processing.service';

interface CourseResponse {
  id: string;
  name: string;
  description: string;
  allow_guests: boolean;
  slug: string;
  modules: CourseModule[]; // NOTE! The back end should have an order field for each lesson in the module_lessons table, but does not need to send the order field to the front end
  videos : Video[];
  links: Link[];
  tags: Tag[];
  duration: number;
  reviews: any ;
  rating: number;
  contributors: Contributor[];
  metaTexts: MetaText[];
  access: any; // required role; required subscription; required purchases etc
  media: any; // 
  created_at: string;
  updated_at: string; // '2023-01-07 13:00:03';
  published_at: string; //'2023-01-07 13:00:03';
  status? : Status[];
}
export interface CourseModulesByCourse {
  id: number, // course id
  name: string, // course name
  slug: string, // course slug, with dashes removed
  modules: CourseModule[]
}

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  courses : Course[] = [];
  courseModules : CourseModulesByCourse[] = [];
  allCoursesForTopic : {topicSlug:string,loaded_at:Date}[] = []; // TODO - we should probably get rid of this
  activeLanguageSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private mediaService : MediaService,
    private trackingService : TrackingService,
    private dataProcessingService: DataProcessingService,
    private authService: AuthService,
    private languageService: LanguageService,
    ) {
      this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
    }

  // getPlaceholder () { // generate a dummy Course

  //   let course : Course = {
  //     id: 100000001,
  //     name : 'How to use CultureConnector in training',
  //     description: '<p>Sit ut dolor pariatur irure ex. Aliquip dolore qui nulla est sunt ad cillum eiusmod duis esse magna do aute. <a href="http://www.cultureconnector.com">cco online</a></p>',
  //     slug: JSON.stringify('this-is-the-course-slug'+Math.random()*1000),
  //     modules: [
  //       {id: 24, order: 1, name: 'Getting familiar with the learner experience', lessons : [
  //         {id : 204, course_module_id: 24, name : 'How to train your dragon', slug : 'how-to-t-your-d', duration: 5, status: []},
  //         {id : 205, course_module_id: 24, name : 'How to eat some cheese', slug : 'how-to-e-some-s', duration: 6, status: []},
  //         {id : 36, course_module_id: 24, name : 'How to spell thoroughly', slug : 'how-to-spell-t', duration: 7, status: []},
  //         {id : 4886, course_module_id: 24, name : 'Inviting participants in < 2 mins', slug : 'inviting-participants-to-cultureconnector-in-less-than-2-minutes', duration: 2, status: []},
  //         {id : 982, course_module_id: 24, name : 'How to drive a train', slug : 'how-to-drive-a-train', duration: 8, status: []},
  //         {id : 12, course_module_id: 24, name : 'How to train a driver', slug : 'how-to-train-a-driver', duration: 9, status: []}
  //     ], duration: null},
  //     {id: 25, order: 2, name: 'Preparing for training', lessons : [
  //       {id : 2104, course_module_id: 25, name : 'How to find resources', slug : 'how-to-find-resources', duration: 5, status: []},
  //       {id : 2105, course_module_id: 25, name : 'How to arrange breaks', slug : 'how-to-arrange-breaks', duration: 6, status: []},
  //       {id : 316, course_module_id: 25, name : 'How to involve outliers', slug : 'how-to-involve-outliers', duration: 7, status: []},
  //       {id : 41886, course_module_id: 25, name : 'How to respond to questions about data', slug : 'how-to-respond-to-q-about-d', duration: 2, status: []},
  //       {id : 9182, course_module_id: 25, name : 'How to manages break-out groups', slug : 'how-to-manage-break-outs', duration: 8, status: []},
  //       {id : 112, course_module_id: 25, name : 'How to inspire change', slug : 'how-to-inspire-change', duration: 9, status: []}
  //   ], duration: null},
  //   {id: 26, order: 2, name: 'Follow-up', lessons : [
  //     {id : 2204, course_module_id: 26, name : 'How to inform learners about access', slug : 'how-to-inform-learners', duration: 5, status: []},
  //     {id : 2205, course_module_id: 26, name : 'How to generate action points', slug : 'how-to-generate-aps', duration: 6, status: []},
  //     {id : 326, course_module_id: 26, name : 'How to monitor implementation', slug : 'how-to-monitor', duration: 7, status: []},
  //     {id : 42886, course_module_id: 26, name : 'How to maintain engagement after training day', slug : 'how-to-maintain-engagements', duration: 2, status: []},
  //     {id : 9282, course_module_id: 26, name : 'How to stay in touch', slug : 'how-to-stay-in-touch', duration: 8, status: []},
  //     {id : 122, course_module_id: 26, name : 'How to plan repeat sessions', slug : 'how-to-plan-repeats', duration: 9, status: []}
  // ], duration: null},
  //     ],
  //     videos : [{name:'Kathryn Libioulle-Clutz, toolbox tool: favourite shoe', id: 10000, host_identifier: '558020191', host_params: 'h=be064152b1'}], // '758075392',
  //     links: null,
  //     tags: null,
  //     duration: null,
  //     reviews: null,
  //     rating: null,
  //     contributors: null,
  //     access: null, // required role etc
  //     status: null, // Status
  //     media: null, // 
  //     created_at: '2023-01-07 13:00:03',
  //     updated_at: '2023-01-07 13:00:03',
  //     published_at: '2023-01-07 13:00:03',
  //   };
  //   return course;
  // };

  clearTranslations (){
    this.courses = [];
    this.courseModules = [];
    this.allCoursesForTopic = [];
  }
  clearData (){
    this.clearTranslations();
  }
  transformCourse (courseResponse){
    let mediaArray : CloudinaryMediaAsset[] = [];
    if (courseResponse.media?.length){
      courseResponse.media.forEach(m => {
        mediaArray.push(Object.assign(m, this.mediaService.setupCloudinaryImageMediaUrls(m)));
      });
    }
    let course : Course = Object.assign(courseResponse, {media:mediaArray});
    course.heading = courseResponse.metaTexts.find(m=>m.category==='heading' && m.type==='primary')?.text;
    course.subheading = courseResponse.metaTexts.find(m=>m.category==='subheading' && m.type==='primary')?.text;
    course.learnings = courseResponse.metaTexts.filter(m=>m.category==='benefits' && m.type==='learn');
    course.target_groups = courseResponse.metaTexts.filter(m=>m.category==='people' && m.type==='target_groups');
    return course;
  }
  transformCourses (coursesFromBackend){
    let transformedCourses : Course[] = [];
    coursesFromBackend.forEach(t=>{
      transformedCourses.push(this.transformCourse(t));
    });
    return transformedCourses;
  }
  getCachedCourse (slug: string){
    return this.courses.find(c=>c.slug === slug);
  }
  getCachedCourses (topicSlug){
    let cachedCourses : Course[] = null;
    if (topicSlug){
      cachedCourses = this.courses.filter(c=>{ return c.topicSlugs.includes(topicSlug);});
    } else {
      cachedCourses = this.courses;
    }
    return cachedCourses
  };
  cacheCourses (courses: Course[]){
    courses.forEach(c=>{
      this.cacheCourse(c);
    });
  };
  cacheCourse (course: Course){
    let cachedCourseIndex = this.courses.findIndex(c=>c.id === course.id);
    if (cachedCourseIndex >-1){
      this.courses[cachedCourseIndex] = course;
    } else {
      this.courses.push(course);
    }
  };
  getCachedCourseModules (courseSlug){
    return this.courseModules.find(c=>c.slug===courseSlug);
  }
  cacheCourseModules (courseModules: CourseModulesByCourse){
    let courseIndex = this.courseModules.findIndex(c=>c.id === courseModules.id);
    if (courseIndex > -1 ){
      this.courseModules[courseIndex] = courseModules;
    } else {
      this.courseModules.push(courseModules);
    }
  };
  insertCulturesIntoCourse(course : Course, cultures:Culture[]){
    course.cultures = cultures.filter(c=>course.cultureSlugs.includes(c.slug));
    return course;
  }
  insertCulturesIntoCachedCourses(cultures:Culture[]){
    if(!cultures?.length || this.courses[0]?.cultures){return;};
    this.courses.forEach (course => {
      course = this.insertCulturesIntoCourse(course,cultures);
    });
  }
  insertTopicsIntoCourse(course : Course, topics:Topic[]){
    course.topics = topics.filter(t=>course.topicSlugs.includes(t.slug));
    return course;
  }
  insertTopicsIntoCachedCourses(topics:Topic[]){
    if(!topics?.length || this.courses[0]?.topics){return;};
    this.courses.forEach (course => {
      course = this.insertTopicsIntoCourse(course,topics);
    });
  }
  getCourse (slug : string, freshFromBackend : boolean){
    if (!slug){alert('Check the address');};
    slug = slug.toLowerCase()
    let cachedCourse : Course = this.getCachedCourse(slug);
    if (cachedCourse && !freshFromBackend){
      return of(cachedCourse);
    };
    
    return this.http.get<{data: CourseResponse}>('api/v1/course/'+slug)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let course : Course =  this.transformCourse(response.data)
              this.cacheCourse(course);
              return course;
            };
          })
        )
  };
  getCourseModules (slug : string, freshFromBackend : boolean){
    if (!slug){alert('Course slug required');};
    slug = slug.toLowerCase()
    let cachedCourseModules : CourseModulesByCourse = this.getCachedCourseModules(slug);
    if (cachedCourseModules && !freshFromBackend){
      return of(cachedCourseModules);
    };
    
    return this.http.get<CourseModulesByCourse>('api/v1/course/modules/'+slug)
        .pipe(
          map(response =>{
            if (response ){
              // let courseModules : CourseModulesByCourse =  this.transformCourseModules(response.data)
              this.cacheCourseModules(response);
              return response;
            };
          })
        )
  };
  getLessonsFromCourseModulesInOrder (modules : CourseModule[] = []){
    return modules.sort((a,b)=>a.order - b.order).map(m => m.lessons.sort((a,b)=>a.order - b.order)).reduce((acc, mappedArray) => acc.concat(mappedArray), []);;
  }
  getLessonExperiencesFromCourseExperiences(courseExperiences : Experience[], experience_verbs : string[], includeArchived : boolean = false){
    return courseExperiences?.length ? courseExperiences.filter(e=> 
      (experience_verbs?.length ? experience_verbs.includes(e.experience_verb) : true) &&
      e.experienceable_type === 'App\\Models\\Lesson' &&
      e.experience_value &&
      (includeArchived ? e.archive_id : true)
    ) : [];
  }
  getFirstLessonInCourse (courseModulesByCourse : CourseModulesByCourse){
    return courseModulesByCourse.modules.sort((a,b)=>a.order - b.order)[0].lessons.sort((a,b)=>a.order - b.order)[0];
  }
  getNextLessonNotYetExperiencedInCourse (courseModulesByCourse : CourseModulesByCourse, courseExperiences : Experience[], experience_verbs = ['consumed']){
    // const allLessonsInOrder = courseModulesByCourse.modules.sort((a,b)=>a.order - b.order).flatMap(m => m.lessons.sort((a,b)=>a.order - b.order));
    // polyfill for flatMap: .reduce((acc, mappedArray) => acc.concat(mappedArray), [])
    const allLessonsInOrder = this.getLessonsFromCourseModulesInOrder(courseModulesByCourse.modules);
    const idsOfConsumedLessons = this.getLessonExperiencesFromCourseExperiences(courseExperiences, experience_verbs).map(e=> e.experienceable_id);
    return allLessonsInOrder.find(l => !idsOfConsumedLessons.includes(l.id));
  }
  getRelatedExperiences (slug : string, withArchivedExperiences : boolean = false){
    if (!slug){alert('Check the address');};
    slug = slug.toLowerCase()
    let cachedCourse : Course = this.getCachedCourse(slug);
    let relatedExperiences : Experience[];
    if (cachedCourse?.id){
      relatedExperiences =  this.trackingService.getCachedExperienceMultiple('course', null, cachedCourse.id, null, withArchivedExperiences)
    };
    if (cachedCourse?.modules){
      cachedCourse.modules.forEach(m=>{
        m.lessons.forEach(l=>{
          let experience = this.trackingService.getCachedExperienceMultiple('lesson', null, l.id, null,withArchivedExperiences);
          if (experience){
            relatedExperiences = relatedExperiences.concat(experience);
          }
        })
      });
    };
    let relatedExperiencesIncludeSomeArchivedExperiences : boolean; // TODO - this is not a good approach!! If we need to view the archive and it does not exist on the backend, we have to call this every time to discover the non-existence

    if (relatedExperiences?.length){
      relatedExperiencesIncludeSomeArchivedExperiences = !!relatedExperiences.find(e=>e.archive_id);
    };
    if (relatedExperiences?.length && !this.trackingService.isTheOnlyExperienceProgress (relatedExperiences) && (!withArchivedExperiences || (withArchivedExperiences && relatedExperiencesIncludeSomeArchivedExperiences))){
      return of(relatedExperiences);
    };

    let params = new URLSearchParams();
    let url = 'api/v1/course/experiences/'+slug+'?';
    
    // if (snapshot_id){
    //   params.append('snapshot_id',snapshot_id.toString())
    // };
    if (withArchivedExperiences){
      params.append('with_archive','true');
    };
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      params.append('guest_uuid',guest.uuid)
    };
    if (!user && !guest){
      throw 'Log in or create guest';
    };
    
    return this.http.get<{relatedExperiences: Experience[]}>(url+params.toString())
        .pipe(
          map(response =>{
            if (response && response.relatedExperiences ){
              this.trackingService.cacheMultipleExperiences(response.relatedExperiences);
              return response.relatedExperiences;
            };
          }),
          catchError((error)=>{
            if (error.error?.message == 'Guest authentication error') {
              this.authService.removeGuestLocally();
              return [];
            } else {
              this.handleError(error);
            }
          }),
        )
  };
  getCourseExperience(course_id : number, experiences: Experience[] = [], experience_verbs : string[] = [], includeArchived : boolean = false){
    // returns the first one it finds
    const backendClass = this.dataProcessingService.convertModelNameToBackendClass('course');
    return Boolean (experiences.find(e=>
      e.experienceable_type === backendClass &&
      (course_id ? e.experienceable_id === course_id : true) &&
      (includeArchived ? e.archive_id : true) &&
      experience_verbs.includes(e.experience_verb) &&
      e.experience_value
    ));
  };
  getCoursesByTopic (topic : string, freshFromServer : boolean){

    // !! WARNING, you can use this to get all topics by providing null as the topic argument, but that will return only the cached courses received from the server so far, which might not be all courses on the server. Add freshFromServer to ensure that you are getting all topics.
    
    let cachedCourses : Course[] = this.getCachedCourses(topic);
    
    if (!freshFromServer && this.allCoursesForTopic.find(t=>t.topicSlug==topic)) {
      return of(cachedCourses);
    }

    let url = 'api/v1/courses?topic='+topic;
    /* for example 
      'api/v1/courses?topic=icpendulum;
    */
    return this.http.get<{data: Course[]}>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              response.data = this.transformCourses(response.data);
              this.cacheCourses(response.data);
              if (!this.allCoursesForTopic.find(t=>t.topicSlug==topic)){
                this.allCoursesForTopic.push({topicSlug:topic,loaded_at:new Date()});
              }
              
              return response.data;
            };
          })
        )
  };
  getCachedCoursesBySlugs (courseSlugs : string[]) : Course[]{

    return this.courses.filter(c=>courseSlugs.includes(c.slug));
    
  };
  convertCoursesToContentItems(courses : Course[]) : ContentItem[] {
    return courses.map(c => this.convertCourseToContentItem(c));
  }
  convertCourseToContentItem(c : Course) : ContentItem {
    return new ContentItem(
          c.id,
          c.name,
          null,
          null,
          c.description,
          null,
          null,
          null,
          c.slug,
          'Go to course',
          'content.go_to_course',
          this.getImage(c.media, 'thumbnail'),
          null,
          null,
          c.rating,
          null,
          new Date(c.published_at),
          c.tags,
          null,
          null
        );
  }
  convertLessonToPlaylistTextItem (lesson:LessonExtraLite, lessonExperiences : Experience[]) : PlaylistTextItem {

    return new PlaylistTextItem (
      lesson.id,
      lesson.slug,
      lesson.name,
      lesson.duration,
      lessonExperiences?.length ? Boolean( lessonExperiences.find(e=>e.experience_verb === 'completed' && e.experience_value === 1)) : false,
      lessonExperiences?.length ? Boolean( lessonExperiences.find(e=>e.experience_verb === 'consumed' && e.experience_value === 1)) : false,
      false, // TODO - handle locking of items
      null, // TODO - add badge feature
    );
  };
  convertModuleToPlaylistTextItemGroup (module:CourseModule,lessonExperiences : Experience[] = []) : PlaylistTextItemGroup{
    let itemGroup = new PlaylistTextItemGroup (
      module.name,
      module?.lessons?.length ? module.lessons.reduce((accumulator,currentValue)=>accumulator+currentValue.duration,0) : 0,
      false, // locked (not implemented yet)
      module?.lessons?.length ? module.lessons.map(l=>this.convertLessonToPlaylistTextItem(l,lessonExperiences.filter(e=>e.experienceable_id === l.id))) : []
    )
    return itemGroup;
  }
  convertModulesToPlaylistText (modules : CourseModule[], lessonExperiences : Experience[]) : PlaylistTextItemGroup[]{
    let playlistText : PlaylistTextItemGroup[] = [];
    if (modules?.length){
      modules.forEach(m=>playlistText.push(this.convertModuleToPlaylistTextItemGroup(m,lessonExperiences)));
    };
    return playlistText;
  }
  // mergeExperiencesIntoLessons (lessons : LessonExtraLite[], experiences: Experience[]) : LessonExtraLite[]{
  //   let experienceable_type : string = this.dataProcessingService.convertModelNameToBackendClass('lesson');
  //   if (lessons?.length && experiences?.length){
  //     lessons.forEach(l=>{
  //       l.experiences = experiences.filter(e=>e.experienceable_type === experienceable_type && e.experienceable_id === l.id);
  //     });
  //   };
  //   return lessons;
  // }
  // mergeExperiencesIntoCourseModules (modules : CourseModule[], experiences: Experience[]) : CourseModule[]{
  //   if (modules?.length){
  //     modules.forEach(m=>this.mergeExperiencesIntoLessons(m.lessons,experiences));
  //   };
  //   return modules;
  // }

  getImage (courseMedia : CloudinaryMediaAsset[], type : string){
    if (!courseMedia?.length){ return null ;};
    if (type === 'thumbnail'){
      return courseMedia.find(m=>m.category==='thumbnail' && m.type==='main');
    }
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    if (errorResponse.error.message == 'Guest authentication error') {
      this.authService.removeGuestLocally();
      return 
      errorMessage = 'authentication.password_wrong';
    } else if (errorResponse.error.message == 'Too many guests.'){
      errorMessage = 'authentication.guest_quota_full';
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
