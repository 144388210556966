import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoModule } from '@jsverse/transloco';

import { CardsModule } from './cards/cards.module';
import { TopicsModule } from './topics/topics.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslocoModule,
    CardsModule,
    TopicsModule,
  ],
  exports: [
  ]
})
export class ContentModule { }
