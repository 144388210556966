import { Injectable } from '@angular/core';
import { ContentItem } from './content-item.model';
import { Insight } from '../contributions/insight.model';
// import { CultureService } from '../cultures';
import { Tag } from '@frontend/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CloudinaryMediaAsset } from '../models';
import { MediaService } from './media';
import { CultureService } from '../cultures';
import { ErrorService } from '../error';

type InsightFromBackend = any; // TODO define this properly

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  insights: Insight[] = [];

  constructor(
    private mediaService: MediaService,
    private cultureService: CultureService,
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  
  convertInsightToContentItem(insight: Insight): ContentItem {

    const culturesAsTags : Tag[] = insight.cultures?.length ? this.cultureService.convertCulturesToTags(insight.cultures,false) : [];


    return new ContentItem( // THIS IS VERY SIMPLE - WE NEED TO handle different kinds of insights with HTML body and other properties
      insight.id,
      insight.s,
      null,
      null,
      null,
      insight.m,
      null,
      null,
      insight.slug,
      null,
      null,
      insight.media?.find(m=>m.category==='thumbnail' && m.type==='main'),
      null,
      null,
      null,
      null,
      null,
      culturesAsTags,
      null,
      null
    )
  }

  transformInsight (insightResponse : InsightFromBackend){
    let mediaArray : CloudinaryMediaAsset[] = [];
    if (insightResponse.media?.length){
      insightResponse.media.forEach(m => {
        mediaArray.push(Object.assign(m, this.mediaService.setupCloudinaryImageMediaUrls(m)));
      });
    }
    let insight : Insight = Object.assign(insightResponse, {media:mediaArray})
    return insight;
  }


  getInsight (id : number, freshFromBackend : boolean){

    let cachedInsight : Insight;
    if (!freshFromBackend){
      cachedInsight = this.getCachedInsight(id);
    }
    if (cachedInsight){
      return of(cachedInsight);
    };

    return this.http.get<{ 'data': Insight }>(
      'api/v1/insights/' + id)
      .pipe(
      map(response => {
        let insight: Insight = this.transformInsight(response.data);
        this.cacheInsight(insight);
        return insight;
      }),
      catchError((error) => {
        return this.handleError(error);
      })
      )
  };
  getCachedInsight (id: number){
    return this.insights.find(t=>t.id === id);
  }
  cacheInsight (insight : Insight){
    let cachedInsightIndex = this.insights.findIndex(t=>t.id === insight.id);
    if (cachedInsightIndex >-1){
      this.insights[cachedInsightIndex] = insight;
    } else {
      this.insights.push(insight);
    }
  };

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    } else {
      const message = errorResponse.error.message;
      const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
      if(standardErrorMessageTranslationKey){
        errorMessage = standardErrorMessageTranslationKey;
      }
    }
    switch (errorResponse.error.message) {
      case 'This action is unauthorized.':
        errorMessage = 'error.permissions_lacking';
        break;
      default:
      // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }

}
