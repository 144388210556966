<h2 *transloco="let t">{{t('content.contribution')}}
  @if(contribution()){
    {{contribution().id}}
  }
</h2>

@if(contributable()){

  <!-- TODO : none of the ready-made generic Card or Link components work well with this content. Make a generic card for ContentItems -->

  <div class="card mb-3">
    <div class="row g-0">
    @if(contributableMedia()){
      <div class="col-md-4">
        <img class="img-fluid rounded-start"
          [src]="getMediaUrlWithTransformations(contributableMedia(), 'w_700,c_fill,ar_16:9/')"
          [alt]="'A background image representing the Contribution'">
      </div>
    }
      <div [class]="contributableMedia() ? 'col-md-8' : 'col-md-12'">
        <div class="card-body">
          <h5 class="card-title">{{contributable().title}}</h5>
          <p class="card-text">{{truncateText(contributable().description,50)}}</p>
          @if(insight()?.subject?.title){
            <p class="card-text"><small class="text-body-secondary">{{insight().subject.title}}</small></p>
          }
        </div>
        @if(insight()?.cultures?.length){
          <div class="card-body">
            <multisite-avatar-pill
            *ngFor="let culture of insight().cultures"
            [removalMode]="false"
            [compact]="true"
            [active]="false"
            [fallbackTranslationKey]="'tracking.missing'"
            [cursorNone]="true"
            [clickable]="false"
            [imageUrl]="getFlagUrlFromHash(culture.flag?.hash,'w_32,c_fill,ar_1:1,r_max,f_auto/')"
            [text]="culture.name"
          ></multisite-avatar-pill>
          </div>
        }
        <!-- <div class="badges mt-3">
          @for (tag of contributable().tags; track tag) {
      
            <span class="mr-1" [ngClass]="[tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">{{tag.name}}</span>
      
          }
      
        </div> -->
      </div>
    </div>
  </div>




}


@if(contribution()){
  <div class="card-gray-200 mb-4 p-3 border-rounded">

    <span *transloco="let t; scope:'contributions'">
      {{t('contributions.anonymity.title')}}:
  
      <span class="font-weight-bold">{{t('contributions.anonymity.'+contribution().anonymity)}}</span>
  
    </span>
    <br>
    <span *transloco="let t;">
      {{t('common.role')}}:
  
      <span class="font-weight-bold">{{t('common.'+contribution().category)}}</span>
  
    </span>
    <div>
      <p class="my-2" *transloco="let t; scope:'contributions'">{{t('contributions.anonymity.description')}}</p>
        <div class="mt-3">
          <multisite-anonymity-level-form
            [contributionAnonymity]="contribution().anonymity"
            (update)="updateAnonymityLevel($event, contribution())"
            [loading]="loadingObject().anonymity"
            [disabled]="isAnythingLoading()"
          ></multisite-anonymity-level-form>
        </div>
  
  
    </div>
  </div>
}

@if(isAnythingLoading()){
  <multisite-loader-globe-animation-detail [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>
}

@if(error()){
  <alert [type]="'warning'" [dismissOnTimeout]="9000" class="my-3" *transloco="let t">
    <strong>{{ t('common.problem')! }}</strong><br>
    <p >{{ errorObject() ? t(errorObject()?.message) : t(errorString()) }}</p>
  </alert>
}

@if(relatedContributions()?.length > 0){
  <h4 *transloco="let t; scope : 'contributions'">{{t('contributions.more_contributions')}}</h4>

  @for(relatedContribution of relatedContributions(); track relatedContribution.id){
  <div class="card-gray-200 mb-4 p-3 border-rounded">

    <!-- <span *transloco="let t; scope:'contributions'">
      {{t('contributions.anonymity.title')}}:
  
      <span class="font-weight-bold">{{t('contributions.anonymity.'+relatedContribution.anonymity)}}</span>
  
    </span> -->
    <br>
    <span *transloco="let t;">
      {{t('common.role')}}:
  
      <span class="font-weight-bold">{{t('common.'+relatedContribution.category)}}</span>
  
    </span>
    <div>
      <p class="my-2" *transloco="let t; scope:'contributions'">{{t('contributions.anonymity.description')}}</p>
  
        <div class="mt-3">
          <multisite-anonymity-level-form
            [contributionAnonymity]="relatedContribution.anonymity"
            (update)="updateAnonymityLevel($event, relatedContribution)"
            [loading]="loadingObject().anonymity"
            [disabled]="isAnythingLoading()"
          ></multisite-anonymity-level-form>
        </div>
  
  
      </div>
    </div>
  }

}