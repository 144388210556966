<span class="icon-pill-{{shape()}} classic" [ngClass]="{active: active(), selectable: selectable(), inverted : inverted(), compact: compact()}">
  @if(iconClasses()){
    <span class="icon">
      <i [class]="iconClasses()"></i>
    </span>
  } @else if (imgUrl()) {
    <span class="icon">
      <img [src]="imgUrl()" class="imgIcon" [alt]="icon()?.name"/>
    </span>
  }
  @if(labelTranslationScope){
    <span *transloco="let t; scope : labelTranslationScope()">{{labelTranslationKey() ?  t(labelTranslationKey()) : labelText()}}</span>
  } @else {
    <span *transloco="let t">{{labelTranslationKey() ?  t(labelTranslationKey()) : labelText()}}</span>
  }
</span>