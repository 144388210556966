import { Component, OnChanges, OnInit, SimpleChanges, input, output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contribution } from '../contribution.model';

@Component({
  selector: 'multisite-anonymity-level-form',
  templateUrl: './anonymity-level-form.component.html',
  styleUrl: './anonymity-level-form.component.scss'
})
export class ContributionAnonymityLevelFormComponent implements OnInit, OnChanges {

  anonymityForm : FormGroup;
  update = output<number>();
  contributionAnonymity = input.required<number>();
  disabled = input<boolean>(false); // TODO - apply this to the object in TS not to the template
  loading = input<boolean>(false);
  levels = [0,10,20,30,40,50,60,70];

  constructor() { }

  reset() {
    this.anonymityForm.patchValue({
      level: this.contributionAnonymity() >= 0 ? this.contributionAnonymity() : null
    });

  }

  initialiseForm() {
    this.anonymityForm = new FormGroup({
      level: new FormControl(null, Validators.required)
    });
    this.reset();
  }
  doUpdate() {
    this.update.emit(this.anonymityForm.value.level);
  }

  ngOnInit() {
    this.initialiseForm();
  }

  ngOnChanges(changesObject : SimpleChanges) {

    if(changesObject.contributionAnonymity && this.anonymityForm && this.anonymityForm.get('level') && this.anonymityForm.get('level').value !== changesObject.contributionAnonymity.currentValue) {
      this.reset();
    }

  }

}
