import { Tip } from "../content";
import { Lesson } from "../courses";
import { Course } from "../courses/course.model";
import { MultipleChoiceQuestion, Survey } from "../surveys";
import { Insight } from "./insight.model";

export class Contribution {

    id: number;
    user_id: number;
    contributable_type: string;
    contributable_id: number;
    contributable: Insight | Tip | MultipleChoiceQuestion | Survey | Lesson | Course;
    category: string;
    type: string;
    visibility: number;
    anonymity: number;
    created_at: string;
    updated_at: string;
    bundledWithRelatedContributions?: boolean; // If true, we have cached this contribution with other contributions for the same contributable and the same user. If false, we've probably fetched this contributable as part of a paginated set of contributions, probably missing other related contributions, so if we want to find all the contributions related to a contributable, a false value here means we need to fetch them.
    insight: Insight | null;
    tip?: Tip | null;
    multiple_choice_question?: MultipleChoiceQuestion | null;
    survey?: Survey | null;
    lesson?: Lesson | null;
    course?: Course | null;
}