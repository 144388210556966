import { AccountDropdownLink } from "./account-dropdown-link.model";

export const AccountDropdownLinks : AccountDropdownLink[] = [
  {  labelTranslationKey:'navigation.settings', routerLinkArray:null, iconClasses:null, filteredRoles:[], clickHandler:null,isHeader:true},
  {  labelTranslationKey:null, routerLinkArray:null, iconClasses:null, filteredRoles:[], clickHandler:null,isDivider:true},
  {  labelTranslationKey:'navigation.account', routerLinkArray:['/account'], iconClasses:'fa-solid fa-user', filteredRoles:[],clickHandler:null},
  {  labelTranslationKey:'common.profile', routerLinkArray:['/profile'], iconClasses:'fa-solid fa-address-card', filteredRoles:[],clickHandler:null},
  {  labelTranslationKey:'navigation.professional', routerLinkArray:['/professional'], iconClasses:'fa-kit fa-interculturalist', filteredRoles:['Interculturalist'],clickHandler:null},
  {  labelTranslationKey:'navigation.design', routerLinkArray:['/design'], iconClasses:'fa-solid fa-paint-roller', filteredRoles:['Admin','Developer'], clickHandler:null},
  {  labelTranslationKey:'navigation.connections', routerLinkArray:['/connections'], iconClasses:'fa-solid fa-people-arrows', filteredRoles:[], clickHandler:null},
  {  labelTranslationKey:'navigation.hub_management', routerLinkArray:['/hubs'], iconClasses:'fa-brands fa-hubspot', filteredRoles:['Interculturalist'], clickHandler:null},
  {  labelTranslationKey:'common.log_out', routerLinkArray:null, iconClasses:'fa-solid fa-right-from-bracket', filteredRoles:[], clickHandler: 'doLogout'},
]