 <div class="content-holder">
  <div class="header align-items-center">
    <p class="title">
      <span *transloco="let t">{{t('content.contributions')}}</span>
    </p>
    <div class="subtitle" *transloco="let t; scope:'contributions'">{{t('contributions.your_accepted_contributions')}}</div>
  </div>
  
  <div class="row body">

    <div class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 mt-2">
    @for(category of available_categories(); track category){

      <multisite-icon-pill
        [fallbackIconClasses]="'fa-duotone fa-solid fa-user-helmet-safety'"
        [compact]="true"
        [labelTranslationKey]="'common.'+category"
        [selectable]="true"
        [inverted]="isSelected('category', category)"
        (click)="toggleParam('category',category)"
        >
      </multisite-icon-pill>
      
    }
    @for(type of contributable_types(); track type){

      <multisite-icon-pill
        [fallbackIconClasses]="'fa-solid fa-wand-magic-sparkles'"
        [compact]="true"
        [labelTranslationKey]="'content.'+type"
        [selectable]="true"
        [inverted]="isSelected('contributable_types', type)"
        (click)="toggleParam('contributable_types',type)"
        >
      </multisite-icon-pill>
      
    }
    @for(level of available_anonymity_level_strings(); track level){

      <multisite-icon-pill
        [fallbackIconClasses]="'fa-regular fa-unlock'"
        [compact]="true"
        [labelTranslationKey]="'contributions.anonymity.'+level"
        [labelTranslationScope]="'contributions'"
        [selectable]="true"
        [inverted]="isSelected('anonymity', level)"
        (click)="toggleParam('anonymity',level)"
        >
      </multisite-icon-pill>
      
    }
    <multisite-icon-pill
      [fallbackIconClasses]="'fa-solid fa-filter'"
      [compact]="true"
      [labelTranslationKey]="'common.all'"
      [selectable]="true"
      [inverted]="!selected_custom_params().length"
      (click)="clearCustomParams()">
    </multisite-icon-pill>
  </div>

  <div class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 mt-2">
    <span class="mx-3">
      <span class="mr-2 fa-duotone fa-solid fa-user-helmet-safety"></span>
      <span *transloco="let t">{{t('common.role')}}</span>
    </span>
    <span class="mx-3">
      <span class="mr-2 fa-solid fa-wand-magic-sparkles"></span>
      <span *transloco="let t; scope: 'contributions'">{{t('contributions.type')}}</span>
    </span>
    <span class="mx-3">
      <span class="mr-2 fa-regular fa-unlock"></span>
      <span *transloco="let t; scope: 'contributions'">{{t('contributions.anonymity.label')}}</span>
      <span class="ml-2 btn btn-link mb-1" (click)="toggleCollapse('anonymity_more_info')" *transloco="let t">{{collapseObject()['anonymity_more_info'] ? t('common.manage') : t('common.close')}}</span>
    </span>

  </div>

  <div class="my-2" [collapse]="collapseObject()['anonymity_more_info']" [isAnimated]="true">
    <div class="alert alert-info mt-3" role="alert">

      <p *transloco="let t; scope:'contributions'" class="mb-2">{{t('contributions.anonymity.description')}}<br>
        <span class="text-muted">
          <span class="fa-solid fa-info-circle mr-1"></span>
          {{t('contributions.anonymity.set_all_help')}}
        </span>
      </p>

        <multisite-anonymity-level-form
          [contributionAnonymity]="anonymity_level_form_current_value()"
          (update)="updateAnonymityLevel($event)"
          [loading]="loadingObject().anonymity"
          [disabled]="isAnythingLoading()"
        ></multisite-anonymity-level-form>
    </div>
    @if(error()){
      <alert [dismissOnTimeout]="5000" [type]="'warning'">
        <span *transloco="let t">{{ t(error()) }}</span>
      </alert>
    }
  </div>

    <hr class="my-4">

    @if(paginatedContributions()){
      <div class="mb-3">

        <multisite-score-highlight
            [titleText]="total_contributions_label()"
            [iconClasses]="'fa-solid fa-wand-magic-sparkles'"
            [score]="paginatedContributions().meta.total"
            [loading]="loadingObject().paginatedContributions"
            [trafficLightNumber]="paginatedContributions().meta.total === 0 ? 0 : 6"
          ></multisite-score-highlight>
      </div>
    }



    <div class="feed-card-holder">

      @for(contribution of paginatedContentItems()?.data; track contribution.id){

        <!-- TODO : none of the ready-made generic Card or Link components work well with this content. Make a generic card for ContentItems -->

        <div class="card my-2">
          @if(contribution.media){
            <img class="card-img-top"
            [src]="contribution.media.asset_url && contribution.media.base_url + 'w_700,c_fill,ar_16:9/' + contribution.media.asset_url"
            [alt]="contribution.media.description ? contribution.media.description : 'A background image representing the Contribution'">
          }
          <div class="card-body">
            <div class="text">
              <h5 class="card-title">{{contribution.title}}</h5>
              <p class="card-text">{{truncateText(contribution.description,50)}}</p>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
              <button type="button" (click)="gotoContribution(contribution)"
                class="btn btn-primary" *transloco="let t">{{t('common.manage')}}
              </button>
            </div>
          </div>
        </div>

      }

     

      @if(paginatedContributions()?.meta.last_page > 1){

        <frontend-pagination-type-three
          [classes]="'pg-purple justify-content-center mt-4'"
          [loading]="loadingObject().paginatedContributions"
          [meta]="paginatedContributions()?.meta"
          [lastPage]="paginatedContributions()?.meta.last_page"
          [links]="paginatedContributions()?.links"
          (changePage)="setPage($event)"
          >
        </frontend-pagination-type-three>
      }
      <div class="my-3">
      </div>

      <multisite-loader-globe-animation-detail
        *ngIf="loadingObject().paginatedContributions"
        width="100"
        height="100"
        class="my-3"
      ></multisite-loader-globe-animation-detail>
      
      <alert [type]="'warning'" *ngIf="!loadingObject().paginatedContributions && !paginatedContributions()?.data?.length">
        <div class="text-center my-5" *transloco="let t">{{ t('common.no_results') }}</div>
      </alert>

    </div>
  </div>

</div>


