import { Component, computed, input } from '@angular/core';



@Component({
  selector: 'multisite-score-highlight',
  templateUrl: './score-highlight.component.html',
  styleUrls: ['./score-highlight.component.scss']
})
export class ScoreHighlightComponent {

  titleText = input<string | undefined>();
  titleTranslationKey = input<string | undefined>();
  titleClasses = input<string | undefined>(undefined); // 'h4 font-weight-bold'
  iconClasses = input<string | undefined>(undefined);  // 'fa-solid fa-star'
  loading = input<boolean>(true);
  score = input.required<number>();
  denominator = input<number | undefined>(undefined);
  trafficLightNumber = input<number | undefined>(undefined);
  rgbColourVariable = input<string | undefined>(undefined); // overrides traffic light, if needed // 'var(--primary-rgb)' or 'var(--bs-success-rgb)' // So far it's not been possible to find a way to provide the RGB values directly rgb(0, 123, 255) so we have to use the CSS variable instead
  fallbackColourVariable = 'var(--primary-rgb)';
  colourRGB = computed<string>(() => {
    return this.rgbColourVariable() ?? (this.trafficLightNumber() === 0 || this.trafficLightNumber() ? `var(--traffic-light-${this.trafficLightNumber()}00-rgb)` : this.fallbackColourVariable);
  });
}
