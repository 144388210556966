<div class="container mb-5 pt-4" *transloco="let t">
  <div class="row">
    <div class="{{'col-md-'+layoutColumns()[0]}} mb-4">
      <multisite-loader-globe-animation-detail
        *ngIf="loadingObject().userAndDelivery && !delivery()"
        [width]="100"
        [height]="100"
      ></multisite-loader-globe-animation-detail>

      @if(myLanguagesApprovedForEditing()?.length > 1) {
        <div class="card-gray-200 mb-4 p-3 border-rounded">
          <multisite-inline-pill-picker
            [items]="myLanguagesApprovedForEditing()"
            [selectedItemIdentifiers]="[this.targetLanguage() && !this.sourceLanguage() ? this.targetLanguage().iso : sourceLanguage()?.iso]"
            [selectedPropertyKey]="'iso'"
            [titleClasses]="'font-weight-bold mb-2'"
            [titleTranslationKey]="'contributions.choose_language_for_creating'"
            [titleTranslationScope]="'contributions'"
            [collapsedPrefixTranslationKey]="null"
            [pillType]="'avatar'"
            (onSelect)="setEditingLanguage($event)"
            (onToggleCollapse)="toggleCollapse('languagePicker')"
            [collapsed]="collapseObject().languagePicker"
            [collapseOnSelect]="true"
            [collapsedItemCount]="2"
          ></multisite-inline-pill-picker>
        </div>
        }

      @if(delivery() && languagesInitialised() && deliverable()){ 
        @if(deliveryEditorType() === 'insight-edit'){
          <multisite-insight-edit
            [delivery]="delivery()"
            [deliverable]="deliverable()"
            [deliveryLocked]="deliveryLocked()"  
            [disabled]="isAnythingLoading()"
            [formCachingServiceIdentifier]="formCachingServiceIdentifier()"
            [parentLoadingObject]="loadingObject()"
            [truncatedTitle]="truncatedTitle()"
            [targetLanguage]="targetLanguage()"
            [sourceLanguage]="sourceLanguage()"
            [deliverable_translations]="deliverable_translations()"
            (saveDeliverable)="save($event,'update')"
            (submitDeliverable)="submit($event)"
          >
          </multisite-insight-edit>
        } @else if (deliveryEditorType() === 'difference-vote'){

          @if(delivery() && cultures() && deliverableCulture()){
            <multisite-difference-vote-cco         
              [delivery]="delivery()"
              [deliverable]="deliverable()"
              [culture]="deliverableCulture()"
              [deliveryLocked]="deliveryLocked()"  
              [disabled]="isAnythingLoading()"
              [formCachingServiceIdentifier]="formCachingServiceIdentifier()"
              [parentLoadingObject]="loadingObject()"
              [truncatedTitle]="truncatedTitle()"
              [targetLanguage]="targetLanguage()"
              [sourceLanguage]="sourceLanguage()"
              [deliverable_translations]="deliverable_translations()"
              [allCultures]="cultures()"
              (saveDeliverable)="save($event,'update')"
              (submitDeliverable)="submit($event)"
            >
            </multisite-difference-vote-cco>
          }
          
        }
      }
      @if(error()){
        <alert [type]="'warning'" [dismissOnTimeout]="9000" class="my-3">
          <strong>{{ t('common.problem')! }}</strong><br>
          <p >{{ errorObject() ? t(errorObject()?.message) : t(errorString()) }}</p>
        </alert>
      }
    </div>
    <div class="{{'col-md-'+layoutColumns()[1]}}">
      <div class="card-gray-200 mb-4 p-3 border-rounded">

        <p *transloco="let t"
        
      >

          @if(delivery()){

            @if(delivery().abandoned_at){
              {{t('tracking.abandoned')}}&nbsp;{{delivery().abandoned_at | date: 'longDate'}}
            } @else if (delivery().completed_at) {
              {{t('tracking.completed')}}&nbsp;{{delivery().completed_at | date: 'longDate'}}
            } @else if (delivery().submitted_at) {
              {{t('tracking.submitted')}}&nbsp;{{delivery().submitted_at | date: 'longDate'}}
            } @else if (delivery().reopen_count) {
              {{t('tracking.reopened')}}&nbsp;{{delivery().updated_at | date: 'longDate'}}
            } @else if (delivery().started_at) {
              {{t('tracking.started')}}&nbsp;{{delivery().started_at | date: 'longDate'}}
            } @else {
              {{t('tracking.created')}}&nbsp;{{delivery().created_at | date: 'longDate'}} ({{t('tracking.not_started')}})
            }
          }

        </p>
        <span *transloco="let t; scope:'contributions'" (click)="toggleCollapse('anonymity')">
          {{t('contributions.anonymity.title')}}:

          <span class="font-weight-bold">{{contributionsAnonymityLevel() < 0 ? t('common.various',{scope:'common'}) : t('contributions.anonymity.'+contributionsAnonymityLevel())}}</span>

          <span class="btn btn-link mb-1">{{
          collapseObject().anonymity
            ? t('common.show_more')
            : t('common.show_less')
        }}</span></span>
        <div [collapse]="collapseObject().anonymity" [isAnimated]="true" >
          <p class="my-2" *transloco="let t; scope:'contributions'">{{t('contributions.anonymity.description')}}</p>
          @for(contribution of contributions(); track contribution.id){

              <div class="mt-3">
                <multisite-anonymity-level-form
                  [contributionAnonymity]="contribution.anonymity"
                  (update)="updateAnonymityLevel($event, contribution)"
                  [loading]="loadingObject().anonymity"
                  [disabled]="isAnythingLoading()"
                ></multisite-anonymity-level-form>
              </div>


            }
          </div>
      </div>
      @if(loadingObject().variants){
      <multisite-loader-globe-animation-detail
        [width]="100"
        [height]="100"
      ></multisite-loader-globe-animation-detail>
      }

      <multisite-national-culture-visualisation-parent
        [culturesWithVariants]="culturesWithVariants()"
        [showIcons]="false"
        [cardHeight]="visualisationCardHeight()"
        [cardWidth]="visualisationCardWidth()"
        [labelType]="visualisationLabelType()"
        [highlightMass]="highlightMassCultureItemsInVisualisation()"
        (toggleLabelType)="toggleVisualisationLabelType()"
        [selectedVariantFiltersDefault]="selectedVisualisationFilters()"
        [show_legend]="show_visualisation_legend()"
        [show_legend_details]="show_visualisation_legend_details()"
        (toggleShowLegend)="toggleShowVisualisationLegend()"
        (toggleShowLegendDetails)="toggleShowVisualisationLegendDetails()"
        [showFilters]="show_visualisation_filters()"
        [showControls]="show_visualisation_controls()"
        (toggleShowFilters)="toggleShowVisualisationFilters()"
        [autoPlayFilterLoop]="autoPlayFilterLoop()"
        [autoPlayFilterLoopLimit]="visualisationFilterLoopAutoPlayLimit()"
      ></multisite-national-culture-visualisation-parent>

      <div class="mt-4">
        <h4 *transloco="let t; scope:'contributions'">{{ t('contributions.editorial.comments') }}</h4>
        <p class="form-text" *transloco="let t; scope:'contributions'">{{t('contributions.editorial.comments_help')}}</p>

        <a
          (click)="toggleCollapse('editorialComments')"
          class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2"
        >
          {{
            collapseObject().editorialComments
              ? t('common.show_more')
              : t('common.show_less')
          }}
          <span *ngIf="collapseObject().editorialComments">
            <i class="fa-solid fa-plus"></i>
          </span>
          <span *ngIf="!collapseObject().editorialComments">
            <i class="fa-solid fa-minus"></i>
          </span>
        </a>
        <div [collapse]="collapseObject().editorialComments" [isAnimated]="true" class="mt-3">
          <comments-post-display-pagination-all-in-one
            [user]="user"
            [rows]="2"
            [disabled]="isAnythingLoading()"
            [loadingComments]="loadingObject().comment"
            [paginatedComments]="paginatedComments()"
            (postComment)="postComment($event)"
            (getComments)="getComments($event, null)"
            [clearCommentForm]="clearCommentForm()"
            [translatablePairs]="translatablePairsForCommenting()"
          >
          </comments-post-display-pagination-all-in-one>
          @if(commentError()){
            <alert [type]="'warning'" [dismissOnTimeout]="9000" class="my-3" *transloco="let t">
              <strong>{{ t('common.problem')! }}</strong><br>
              <p >{{ commentErrorObject() ? t(commentErrorObject()?.message) : t(commentErrorString()) }}</p>
            </alert>
          }
        </div>
      </div>

      @if(loadingObject().instructions){
      <multisite-loader-globe-animation-detail
        [width]="100"
        [height]="100"
      ></multisite-loader-globe-animation-detail>
      }
      @if(instructions()?.length){
        <!-- TODO separate the following div into its own component -->
      <div class="mt-4">
        <h4 *transloco="let t">{{ t('common.instructions') }}</h4>
        <a
          (click)="toggleShowInstructionDetails()"
          class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2"
        >
          {{
            show_instruction_details()
              ? t('common.show_less')
              : t('common.show_more')
          }}
          <span *ngIf="!show_instruction_details()">
            <i class="fa-solid fa-plus"></i>
          </span>
          <span *ngIf="show_instruction_details()">
            <i class="fa-solid fa-minus"></i>
          </span>
        </a>

        @for(instructionType of instructionTypes(); track instructionType){
        <div class="row">
          <div class="col-12 mt-4">
            <h5 *transloco="let t; scope: 'contributions'">
              {{ t('contributions.instruction.' + instructionType) }}
            </h5>
          </div>

          <div class="col-12 list-card-group">
            @for(instruction of getInstructionsOfType(instructionType); track
            instruction.id){
            <div
              class="list-item-card"
              [ngClass]="{ clickable: instruction.m }"
              (click)="
                toggleObject['instructions_' + instruction.id] =
                  !toggleObject['instructions_' + instruction.id]
              "
            >
              <div class="header">
                <div class="left">
                  @if (instruction.icon) {
                  <div class="icon-holder">
                    <i
                      class="fa-solid {{
                        instruction.icon?.identifier_1 ?? 'fa-check'
                      }} icon-size"
                    ></i>
                  </div>
                  }
                  <p class="title">{{ instruction.s }}</p>
                </div>
                @if(instruction.m){
                <div class="right">
                  <span
                    class="open"
                    *ngIf="
                      !show_instruction_details() &&
                      !toggleObject['instructions_' + instruction.id]
                    "
                  >
                    <i class="fa-solid fa-plus"></i>
                  </span>
                  <span
                    class="open"
                    *ngIf="
                      show_instruction_details() ||
                      toggleObject['instructions_' + instruction.id]
                    "
                  >
                    <i class="fa-solid fa-minus"></i>
                  </span>
                </div>
                }
              </div>
              @if(instruction.m){
              <div class="content">
                <p
                  class="description"
                  [collapse]="
                    !show_instruction_details() &&
                    !toggleObject['instructions_' + instruction.id]
                  "
                  [isAnimated]="true"
                >
                  {{ instruction.m }}
                </p>
              </div>
              }
            </div>
            }
          </div>
        </div>
        }
      </div>
      }
    </div>
  </div>
</div>
<ng-template #submissionModal>
  <div class="modal-header">
    @if(submissionSuccessful()){
      <h4 class="modal-title pull-left" *transloco="let t">{{t('common.success')}}</h4>
    } @else {
      <h4 class="modal-title pull-left" *transloco="let t; scope : 'contributions'">{{t('contributions.confirm.translation')}}</h4>
    }
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true" class="visually-hidden">
        &times;
      </span>
    </button>
  </div>
  <div class="modal-body">
    @if(submissionSuccessful()){
      <div class="d-flex justify-content-center">
        <multisite-randomised-message-card
        [titleTranslationKey]="'common.thank_you'"
        [descriptionTranslationKey]="'contributions.praise.intercultural_work'"
        [descriptionTranslationScope]="'contributions'"
        [randomDescriptionNumberMax]="16"
        [captionTranslationKey]="'contributions.praise.thanks_for_contribution'"
        [captionTranslationScope]="'contributions'"
        [randomCaptionNumberMax]="16"
        [gallery_category]="'gallery'"
        [gallery_type]="'banners'"
        [gallery_tag_query_params]="successModalImageTagQueryParams"
        ></multisite-randomised-message-card>
      </div>
      <div class="d-flex justify-content-center">
        <button
          *transloco="let t"
          (click)="closeModal()"
          class="btn btn-primary mt-2"
          type="button"
        >
          {{ t('common.close') }}
        </button>
      </div>
    } @else {
      @if(sourceLanguage()){
        @if(submissionForm){
          <form
          [formGroup]="submissionForm"
          role="form"
          class="d-flex flex-column w-100"
          *transloco="let t"
        >
          <div class="input-group-submit">
            <div class="d-flex flex-column w-100 gap-2">
              <div class="selectable-check card-gray-200">
                <input
                  class="form-check-input m-0"
                  type="checkbox"
                  formControlName="approveTranslation"
                  id="confirm"
                />
                <label class="form-check-label" for="confirm" *transloco="let t; scope : 'contributions'">{{t('contributions.confirm.translation_meaning')}}
                </label>
              </div>
            </div>
          </div>
          @if(submissionForm.get('submitMultipleLanguages')){
            <div class="mx-4">
              <legend class="mb-0" *transloco="let t; scope:'contributions'">{{t('contributions.confirm.versions')}}</legend>
              <p class="form-text" *transloco="let t; scope:'contributions'">{{t('contributions.confirm.versions_help')}}</p>
            </div>
            <div class="input-group-submit">
              <div class="d-flex flex-column w-100 gap-2">
                <div class="selectable-check card-gray-200">
                  <input
                    class="form-check-input m-0"
                    type="radio"
                    formControlName="submitMultipleLanguages"
                    id="inlineRadio1"
                    value="target-only"
                  />
                  <label class="form-radio-label" for="inlineRadio1">
                    {{ targetLanguage()?.name }}
                  </label>
                </div>
                <div class="selectable-check card-gray-200">
                  <input
                    class="form-check-input m-0"
                    type="radio"
                    formControlName="submitMultipleLanguages"
                    id="inlineRadio2"
                    value="target+source"
                  />
                  <label class="form-radio-label" for="inlineRadio2">
                    {{ targetLanguage()?.name }} +
                    {{ sourceLanguage().name }}
                  </label>
                </div>
              </div>
            </div>
          }
        </form>
        }
      }
      <div class="d-flex justify-content-end">
        <button
          [disabled]="submissionForm?.invalid || isAnythingLoading()"
          (click)="confirmSubmission()"
          class="btn btn-primary mt-2"
          type="button"
          *transloco="let t"
        >
          <multisite-loader-globe-animation-detail
            *ngIf="loadingObject().submit"
            [width]="24"
            [height]="24"
          ></multisite-loader-globe-animation-detail>
          
          {{ t('common.submit') }}
        </button>
      </div>
      @if(error()){
        <alert [type]="'warning'" [dismissOnTimeout]="9000" class="my-3" *transloco="let t">
          <strong>{{ t('common.problem')! }}</strong><br>
          <p >{{ errorObject() ? t(errorObject()?.message) : t(errorString()) }}</p>
        </alert>
      }
    }
  </div>
</ng-template>
