import { Injectable } from '@angular/core';
import { Delivery } from './delivery.model';
import { Observable, Subscription, catchError, map, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataProcessingService } from '../utilities/data-processing.service';
import { LanguageService } from '../language';
import { Contribution } from './contribution.model';
import { ContentItem, InsightsService, TipService } from '../content';
import { SurveysService } from '../surveys';
import { CourseService } from '../courses/course.service'; // ERROR will occur if you use the shortcut from '../courses'
import { LessonService } from '../courses/lesson.service'; // ERROR will occur if you use the shortcut from '../courses'
import { TranslocoService } from '@jsverse/transloco';
import { ErrorService } from '../error';

interface Report {
  name: string;
  allowed_filters: string[];
  allowed_options: string[];
  url: string;
}
export interface ReportData {
  report: Report;
  data: { [key: string]: any };
  filters: { [key: string]: any };
  options: { [key: string]: any };
  requested_at: Date;
}
export type SavedReportFiltersAndOptions = {
  filters: { [key: string]: any },
  options: { [key: string]: any },
  settings: { [key: string]: any }
};
export interface DeliveryType {
  category: string;
  type: string;
  labelTranslationKey: string;
  iconClasses: string;
  icon_name?: string;
}
export interface DeliveryStatus {
  labelTranslationKey: string;
  badgeClasses?: string; // badge-danger, badge-warning, badge-success etc
  icon_name?: string;
  iconClasses?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  reports: Report[] = [];
  deliveries: Delivery[] = []; // only a few standalone deliveries, downloaded individually on their on page
  contributions: Contribution[] = []; // only a few standalone contributions, downloaded individually (grouped by contributable)
  reportsData: ReportData[] = [];
  reportFilters: { [key: string]: any } = {};
  reportOptions: { [key: string]: any } = {};
  reportSettings: { [key: string]: any } = {}; // only for the frontend, never sent to the backend
  savedReportFiltersAndOptions: SavedReportFiltersAndOptions[] = [];
  activeLanguageSubscription: Subscription;
  private _languageSlugsAllowedForTextContentCreation = [
    'en',
    'fr',
  ];
  private _contribution_types : DeliveryType[] = [
    {category: 'text', type: 'create', labelTranslationKey: 'content_management.write_text', icon_name: 'edit', iconClasses: 'fa-solid fa-pen'},
    {category: 'video', type: 'create', labelTranslationKey: 'content_management.create_video', icon_name: 'video', iconClasses: 'fa-solid fa-video'},
    {category: 'opinion', type: 'vote', labelTranslationKey: 'call_to_action.give_your_opinion', icon_name: 'vote', iconClasses: 'fa-solid fa-check-to-slot'},
  ];
  private _contribution_categories : string[] = ['creator','translator','presenter','reviewer','editor']; // keys for translations in en.json 'common' 
  private _contribution_anonymity_levels : number[] = [0,10,20,30,40,50,60,70]; // also keys for translations in en.json 'contributions' 
  private _contributable_types : string[] = ['insights','multiple_choice_questions','tips']; // keys for translations in en.json 'content' object


  constructor(
    private http: HttpClient,
    private router: Router,
    private dataProcessingService: DataProcessingService,
    private languageService: LanguageService,
    private insightService: InsightsService,
    private tipService: TipService,
    private courseService: CourseService,
    private lessonService: LessonService,
    private surveysService: SurveysService,
    private translocoService : TranslocoService,
    private errorService: ErrorService,
  ) { 

    this.reports = [
      {
          name: "myPaginatedDeliveries",
          allowed_filters: [],
          allowed_options: ['items_per_page', 'sort_by', 'sort_order', 'include_expired', 'page'],
          url: 'api/v1/deliveries/my-paginated-deliveries',
      },
      {
          name: "myPaginatedContributions",
          allowed_filters: ['category','anonymity'],
          allowed_options: ['items_per_page', 'sort_by', 'sort_order', 'page', 'contributable_types'],
          url: 'api/v1/contributions/my-paginated-contributions',
      },
    ];
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearData();
        // this.getData('myPaginatedDeliveries').subscribe(); // This causes all deliveries to be loaded every time the application boots
    });

  }

  get languageSlugsAllowedForTextContentCreation(){
    return this._languageSlugsAllowedForTextContentCreation;
  }
  clearContributions() {
    this.contributions = [];
    this.clearReportData(['myPaginatedContributions']);
  }
  clearData() {
    const reportNames = this.reports.map(report => report.name);
    this.clearReportData(reportNames);
    this.clearDeliveries();
  }
  get contribution_categories() : string[] {
    return this._contribution_categories;
  };
  get contributable_types() : string[] {
    return this._contributable_types;
  }
  get contribution_anonymity_levels() : number[] {
    return this._contribution_anonymity_levels;
  }
    // generateFakeDeliveries(): Delivery[] {
    //   const deliveries: Delivery[] = [];
    //   for (let i = 0; i < 10; i++) {
    //     deliveries.push({
    //       id: i + 1,
    //       task: new Task(i+1, 'slug' + (i + 1), 'name' + (i + 1), 'description' + (i + 1), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), null, null, null),
    //       subject: new Subject(i+1, 'title' + (i + 1), 'slug' + (i + 1), 'description' + (i + 1), 'category' + (i + 1), 'type' + (i + 1)),
    //       insight: new Insight(i+1, 'slug' + (i + 1), 's' + (i + 1), 'm' + (i + 1), 'l' + (i + 1), Math.floor(Math.random() * 100), new Date(), 'category' + (i + 1), 'type' + (i + 1), null, new Date(), new Date(), new Date(), null),
    //       source_lang: 'en',
    //       target_lang: 'fr',
    //       user_id: Math.floor(Math.random() * 1000),
    //       handler_id: Math.floor(Math.random() * 1000),
    //       started_at: new Date(),
    //       submitted_at: null,
    //       reopen_count: Math.floor(Math.random() * 5),
    //       abandoned_at: null,
    //       completed_at: null,
    //       credits: Math.floor(Math.random() * 100),
    //       cultures: [],
    //       topics: []
    //     });
    //   }
    //   return deliveries;
    // }

    // generateFakePaginatedDeliveries(): PaginatedDeliveries {
    //   const deliveries = this.generateFakeDeliveries();
    //   return {
    //     data: deliveries,
    //     links: {
    //       first: 'http://first',
    //       last: 'http://last',
    //       prev: 'http://prev',
    //       next: 'http://next'
    //     },
    //     meta: {
    //       current_page: 1,
    //       from: 1,
    //       last_page: 10,
    //       path: 'http://path',
    //       per_page: 10,
    //       to: 10,
    //       total: 100
    //     }
    //   };
    // };

    getDeliveryType(category:string,type:string) : DeliveryType {
      // Delivery types are in fact Task types (delivery->task->type)
      return this._contribution_types.find(contribution_type => contribution_type.category === category && contribution_type.type === type);
    }
    getDeliveryStatus(delivery : Delivery) : DeliveryStatus | null {
      if(!delivery){return null;};
      if(delivery.submitted_at){
        if(!delivery.completed_at){
          return {labelTranslationKey:'tracking.submitted',iconClasses:'fa-solid fa-circle-dot',badgeClasses:'badge-info'};
        } else {
          return {labelTranslationKey:'tracking.completed',iconClasses:'fa-solid fa-check',badgeClasses:'badge-success'};
        }
      } else if(delivery.started_at) {
        return {labelTranslationKey:'tracking.in_progress',iconClasses:'fa-solid fa-arrow-rotate-right fa-spin',badgeClasses:'badge-warning'};
      } else {
        return {labelTranslationKey:'tracking.not_started',iconClasses:'fa-solid fa-circle',badgeClasses:'badge-info'};
      }
    }

    clearReportData(reportNames: string[]): boolean {
      const foundCachedReportsData = this.reportsData.filter(reportData => reportNames.includes(reportData.report.name));
      this.reportsData = this.reportsData.filter(reportData => !(reportNames.includes(reportData.report.name)));

      return foundCachedReportsData.length > 0;
    };
    clearDeliveries(): void {
      this.deliveries = [];
    }

  copyReportData(reportData: ReportData) { // so that the user can change the filters without changing the cached data

      return Object.assign({}, reportData, {
          filters: reportData.filters ? JSON.parse(JSON.stringify(reportData.filters)) : null,
          options: reportData.options ? JSON.parse(JSON.stringify(reportData.options)) : null,
      });

  }
  filterTheFilters(filters, allowedFilters) {

      return Object.keys(filters)
          .filter(key => allowedFilters.includes(key))
          .reduce((filtered, key) => {
              filtered[key] = filters[key];
              return filtered;
          }, {});

  };
  getCachedReportDataIndex(reportsDataToFilter: ReportData[], reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }): number {

    return reportsDataToFilter.findIndex(reportData => {

        if (
            reportData.report.name === reportName &&
            ((!reportData.filters && !filters) || this.dataProcessingService.matchTwoComplexObjects(reportData.filters, filters)) &&
            ((!reportData.options && !options) || this.dataProcessingService.matchTwoComplexObjects(reportData.options, options))
        ) {
            return true;
        }
        return false;
    });
};
convertContributionsWithContributablesToContentItems(contributions: Contribution[]): ContentItem[] {
    const translation = this.translocoService.translate('error.could_not_load');
    return contributions.map(contribution => {
      let contentItem;
      if (contribution.contributable_type === 'App\\Models\\Insight' && contribution.insight) {
        contentItem = this.insightService.convertInsightToContentItem(contribution.insight);
      } else if (contribution.contributable_type === 'App\\Models\\Tip' && contribution.tip) {
        contentItem = this.tipService.convertTipToContentItem(contribution.tip);
      } else if (contribution.contributable_type === 'App\\Models\\MultipleChoiceQuestion' && contribution.multiple_choice_question) {
        contentItem = this.surveysService.convertMultipleChoiceQuestionToContentItem(contribution.multiple_choice_question);
      } else if (contribution.contributable_type === 'App\\Models\\Lesson' && contribution.lesson) {
        contentItem = this.lessonService.convertLessonToContentItem(contribution.lesson);
      } else if (contribution.contributable_type === 'App\\Models\\Survey' && contribution.survey) {
        contentItem = this.surveysService.convertSurveyToContentItem(contribution.survey);
      } else if (contribution.contributable_type === 'App\\Models\\Course' && contribution.course) {
        contentItem = this.courseService.convertCourseToContentItem(contribution.course);
      }
      if (!contentItem) {
        contentItem = new ContentItem(null, translation, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, {})
      }
      contentItem.meta = { ...contentItem.meta, contribution: contribution };
      return contentItem;
    });
}
getCachedReportDataFiltered(reportsDataToFilter: ReportData[], reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }): ReportData[] {

    return reportsDataToFilter.filter(reportData => {

        if (
            reportData.report.name === reportName &&
            ((!reportData.filters && !filters) || this.dataProcessingService.matchTwoComplexObjects(reportData.filters, filters)) &&
            ((!reportData.options && !options) || this.dataProcessingService.matchTwoComplexObjects(reportData.options, options))
        ) {
            return true;
        }
        return false;
    });
};
private transformContribution(contribution: Contribution, markAsBundledWithContributable : boolean = false): Contribution {
 contribution['bundledWithRelatedContributions'] = markAsBundledWithContributable;
 return contribution;
}
private cacheContribution(contribution : Contribution, markAsBundledWithContributable : boolean = false): void {

  const foundStandaloneContributionIndex = this.contributions.findIndex(c => c.id === contribution.id);

  contribution = this.transformContribution(contribution,markAsBundledWithContributable);

  if(foundStandaloneContributionIndex > -1){
    this.contributions[foundStandaloneContributionIndex] = contribution;
  } else {
    this.contributions.push(contribution)
  }
}
private cacheContributions(contributions : Contribution[], markAsBundledWithContributable : boolean = false): void {

  contributions.forEach(c => this.cacheContribution(c,markAsBundledWithContributable));

}
deliveryFilter(deliverable_type: string, task_type: string, with_translations:string, with_meta : boolean, queryParams: any = {}) {
  return (delivery: any) => {
    return (
      delivery[deliverable_type] &&
      delivery.task?.type === task_type &&
      (with_translations ? delivery[with_translations] : true) &&
      (with_meta ? delivery.task?.meta : true) &&
      (queryParams && Object.keys(queryParams).length > 0
        ? Object.keys(queryParams).every(key => delivery[key] === queryParams[key])
        : true)
    );
  };
}
private findCachedDelivery(task_type: string /* e.g. 'create' */, deliverable_type: string /* e.g. 'insight' */, deliverable_identifier:string | number, with_translations : string, with_meta : boolean, queryParams : {[key:string]: any}): Delivery {
  const myPaginatedDeliveriesFlattened = this.reportsData
    .filter(reportData => reportData.report.name === 'myPaginatedDeliveries')
    .reduce((acc, reportData) => acc.concat(reportData.data.data), [])
    .filter(this.deliveryFilter(deliverable_type, task_type,with_translations,with_meta,queryParams)); // has possible duplicates because a delivery may appear in different sorts and filters of the paginated data

  const standaloneDeliveries = this.deliveries.filter(this.deliveryFilter(deliverable_type, task_type, with_translations, with_meta, queryParams));

  const allDeliveries = myPaginatedDeliveriesFlattened.concat(standaloneDeliveries);

  return allDeliveries.find(delivery => (typeof deliverable_identifier === 'string' ? delivery[deliverable_type]?.slug === deliverable_identifier : false) || (typeof deliverable_identifier === 'number' ? delivery[deliverable_type]?.id === deliverable_identifier : false)); // The first duplicate is good enough
}
private findCachedContribution(contribution_id: number): Contribution {
  const myPaginatedContributionsFlattened = this.reportsData
    .filter(reportData => reportData.report.name === 'myPaginatedContributions')
    .reduce((acc, reportData) => acc.concat(reportData.data.data), [])
    .filter(c=>c.id === contribution_id); // has possible duplicates because a delivery may appear in different sorts and filters of the paginated data

  const standaloneContributions = this.contributions.filter(c=>c.id === contribution_id);

  const allContributions = myPaginatedContributionsFlattened.concat(standaloneContributions);

  return allContributions.find(c => c.id == contribution_id); // The first duplicate is good enough
}
private transformDelivery(delivery: any): Delivery {
  const dateProperties = ['created_at', 'started_at', 'submitted_at', 'abandoned_at', 'completed_at'];

  dateProperties.forEach(prop => {
    if (delivery[prop]) {
      delivery[prop] = new Date(delivery[prop]);
    }
  });

  return delivery;
}
private cacheDelivery(delivery : Delivery, task_type: string /* e.g. 'create' */, deliverable_type: string /* e.g. 'insights' */, deliverable_identifier:string | number, with_translations:string, queryParams : {[key:string]: any}): Delivery {

  const standaloneDeliveryIds = this.deliveries.filter(this.deliveryFilter(deliverable_type, task_type, with_translations, false, queryParams)).map(delivery => delivery.id);

  standaloneDeliveryIds.forEach(standaloneDeliveryId => {
      const cachedDeliveryIndex = this.deliveries.findIndex(delivery => delivery.id === standaloneDeliveryId);
      this.deliveries[cachedDeliveryIndex] = delivery;
    });

  if(!standaloneDeliveryIds.length){
    this.deliveries.push(delivery);
  }
  return delivery;
}
getMyDelivery(
  task_type: 'create' | 'vote', // | 'review' | 'translate', 
  deliverable_type: 'insight' | 'vote', 
  deliverable_identifier: string | number, 
  with_translations?: string, // 'insight_translations' | 'vote_translations' | 'something_translations'
  with_meta: boolean = true, // the lite version of the delivery.task object has no meta (e.g. word/character limits)
  queryParams?: { [key: string]: any }
): Observable<Delivery> {

  const cachedDelivery = this.findCachedDelivery(task_type, deliverable_type, deliverable_identifier, with_translations, with_meta, queryParams);

  if (cachedDelivery) {
      return of(cachedDelivery);
  }

  let url = 'api/v1/deliveries/my-delivery';
  const params : {task_type : string, deliverable_type:string} = {task_type, deliverable_type};

  if (!isNaN(Number(deliverable_identifier))) {
    params['deliverable_id'] = Number(deliverable_identifier);
  } else {
    params['deliverable_slug'] = deliverable_identifier;
  }

  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    url += `?${queryString}`;
  }

  return this.http.post<{data:Delivery}>(url,params)
      .pipe(
          map((response) => {
            const delivery = this.transformDelivery(response.data); // as Delivery;
              return this.cacheDelivery(delivery, task_type, deliverable_type, deliverable_identifier, with_translations, queryParams); 
          }),
          catchError(error => this.handleError(error))
      );
}
deliveryStatusChanged(existing_delivery: Delivery, updated_delivery : Delivery): boolean {
  const comparisonProperties = ['id', 'started_at', 'submitted_at', 'abandoned_at', 'reopen_count', 'completed_at'];
  return comparisonProperties.some(prop => existing_delivery[prop] !== updated_delivery[prop]);
}
updateDelivery(delivery: Delivery, formData : {[key:string]:any}, deliverable_type: 'insight' | 'vote', deliverable_identifier: string | number, action : 'update' | 'submit') : Observable<Delivery> {

  const params = {...formData, delivery_id: delivery.id};
  if(formData['translations']){
    params['translations'] = formData['translations'];
  }
  const url = action === 'update' ? 'api/v1/deliveries/my-delivery/update' : 'api/v1/deliveries/my-delivery/submit';
  return this.http.post<{data:Delivery}>(url,params)
      .pipe(
          map((response) => {
            const updated_delivery = this.transformDelivery(response.data); // as Delivery;
            if(this.deliveryStatusChanged(delivery, updated_delivery)){
              this.clearReportData(['myPaginatedDeliveries']);
              this.deliveries = this.deliveries.filter(d => d.id !== updated_delivery.id);
            }
            return this.cacheDelivery(updated_delivery, delivery.task.type, deliverable_type, deliverable_identifier, null, null);
          }),
          catchError(error => this.handleError(error))
      );
}
private findCachedContributionsByContributable(contributable_type: string /* e.g. 'App\Models\Insight' */, contributable_id: number = null, contributable_slug: string = null): Contribution[] {
  return this.contributions.filter(contribution => contribution.contributable_type === contributable_type && contribution.contributable_id === contributable_id && contribution.bundledWithRelatedContributions);
}
updateContribution(contribution: Contribution, formData : {[key:string]:number}) : Observable<Contribution> {
  
  const url = 'api/v1/contributions/my-contribution/update/' + contribution.id;
  const params = formData;
  return this.http.post<{data:Contribution}>(url,params)
      .pipe(
          map((response) => {
            const updated_contribution = response.data;
            this.cacheContribution(updated_contribution,contribution.bundledWithRelatedContributions ?? false);
            return updated_contribution;
          }),
          catchError(error => this.handleError(error))
      );
}
updateAllContributions(formData : {[key:string]:number}) : Observable<boolean> {
  
  const url = 'api/v1/contributions/my-contributions/update';
  const params = formData;
  return this.http.post<{message:string}>(url,params)
      .pipe(
          map(() => {
            this.clearContributions()
            return true;
          }),
          catchError(error => this.handleError(error))
      );
}

getMyContributionsByContributable(
  contributable_type: 'insight' | 'lesson' | 'course' | 'tip' | 'survey' | 'multiple_choice_question', 
  contributable_id: number = null, 
  contributable_slug: string = null,
): Observable<Contribution[]> {

  if(!contributable_slug && !contributable_id){
    throw 'id or slug required'
  } 

  const cachedContributions = this.findCachedContributionsByContributable(this.dataProcessingService.convertModelNameToBackendClass(contributable_type), contributable_id,contributable_slug);

  if (cachedContributions.length) {
      return of(cachedContributions);
  }

  let url = 'api/v1/contributions/my-contributions-by-contributable';
  const params = {contributable_type};

  if (contributable_id) {
    params['contributable_id'] = contributable_id;
  } else {
    params['contributable_slug'] = contributable_slug;
  }

  return this.http.post<{data:Contribution[]}>(url,params)
      .pipe(
          map((response) => {
            const contributions = response.data;

            this.cacheContributions(response.data,true);
            return response.data;
          }),
          catchError(error => this.handleError(error))
      );
}
getContribution(contribution_id: number): Observable<Contribution> {
  
  // NOTE it is better NOT to use this endpoint. We get more useful data from getMyContributionsByContributable because then we know if we have all the contributions related to a single contributable.

  const cachedContribution = this.findCachedContribution(contribution_id);

  if (cachedContribution) {
      return of(cachedContribution);
  }

  let url = 'api/v1/contributions/my-contribution/' + contribution_id;

  return this.http.get<{data:Contribution}>(url)
      .pipe(
          map((response) => {
            const contribution = response.data;
            this.cacheContribution(contribution, false);
            return contribution;
          }),
          catchError((error) => {
            debugger;
            return this.handleError(error)
          })
      );
}
cacheReportData(reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }, data: { [key: string]: any }): ReportData {
  let existingReportDataIndex = this.getCachedReportDataIndex(this.reportsData, reportName, filters, options);
  let reportData = {
      report: this.reports.find(report => report.name === reportName),
      requested_at: new Date(),
      filters: filters,
      options: options,
      data: data,
  };
  if (existingReportDataIndex > -1) {
      this.reportsData[existingReportDataIndex] = reportData;
  } else {
      this.reportsData.push(reportData);
  }
  return reportData;

};
    getData(reportName: string, filters: { [key: string]: any } | null = {}, options: { [key: string]: any } | null = {}, freshFromServer: boolean = false): Observable<ReportData> {

      if (filters === null) {
          filters = {};
      }
      if (options === null) {
          options = {};
      }

      let report = this.reports.find(report => report.name === reportName);

      if (!report) {
          throw 'Report not found';
      }

     
      if (report.allowed_filters) {
          filters = Object.keys(filters).reduce((filtered, key) => {
              if (report.allowed_filters.includes(key)) {
                  filtered[key] = filters[key];
              }
              return filtered;
          }, {});
      }
      if (report.allowed_options) {
          options = Object.keys(options).reduce((filtered, key) => {
              if (report.allowed_options.includes(key)) {
                  filtered[key] = options[key];
              }
              return filtered;
          }, {});
      }

      let cachedDataIndex = this.getCachedReportDataIndex(this.reportsData, reportName, filters, options);

      if (!freshFromServer && cachedDataIndex > -1) {
          this.reportsData[cachedDataIndex].requested_at = new Date();
          return of(this.copyReportData(this.reportsData[cachedDataIndex]));
      } else {

          let params: { [key: string]: any } = {};

          params.filters = filters;
          params.options = options;
          // for (let key in options) {
          //     params[key] = options[key];
          // }


          return this.http.post<ReportData>(report.url, params)

              .pipe(
                  map((response) => {

                      return this.copyReportData(this.cacheReportData(reportName, filters, options, response));
                  }),
                  catchError(error => this.handleError(error))
              );

      }
  };
  getReportConfig(reportName: string): Report {
    return this.reports.find(report => report.name === reportName);
  };
  getRequestFilters(reportName: string, savedReportSettings: SavedReportFiltersAndOptions): { [key: string]: any } {
      let request_filters = {};
      let report = this.getReportConfig(reportName);
      if (report && report.allowed_filters && savedReportSettings.filters) {
          report.allowed_filters.forEach(allowed_filter => {
              if (savedReportSettings.filters[allowed_filter]) {
                  if (
                      savedReportSettings.filters[allowed_filter].from ||
                      savedReportSettings.filters[allowed_filter].to ||
                      (savedReportSettings.filters[allowed_filter].from === undefined && savedReportSettings.filters[allowed_filter].to === undefined)) {

                      request_filters[allowed_filter] = savedReportSettings.filters[allowed_filter];
                  }

              }
          });
      }
      return request_filters;
  };
  getRequestOptions(reportName: string, savedReportSettings: SavedReportFiltersAndOptions): { [key: string]: any } {
      let request_options = {};
      let report = this.getReportConfig(reportName);
      if (report && report.allowed_options && savedReportSettings.options) {
          report.allowed_options.forEach(allowed_option => {
              if (savedReportSettings.options[allowed_option]) {
                  //   if( 
                  //     savedReportSettings.options[allowed_option].from ||
                  //     savedReportSettings.options[allowed_option].to ||
                  //     (savedReportSettings.options[allowed_option].from === undefined && savedReportSettings.options[allowed_option].to === undefined)){

                  //       request_options[allowed_option] = savedReportSettings.options[allowed_option];
                  //   }
                  request_options[allowed_option] = savedReportSettings.options[allowed_option];

              }
          });
      }
      return request_options;
  };
    private handleError(errorResponse: HttpErrorResponse) {
      let errorMessage = 'error.something_went_wrong';
      if (!errorResponse.error || !errorResponse.error.message) {
        return throwError(errorMessage);
      } else {
        const message = errorResponse.error.message;
        const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
        if(standardErrorMessageTranslationKey){
          errorMessage = standardErrorMessageTranslationKey;
        }
      }
      switch (errorResponse.error.message) {
        case 'This action is unauthorized.':
          errorMessage = 'error.permissions_lacking';
          break;
        case 'Unauthenticated.':
          this.router.navigate(['/login']); // TODO - consider handling this by route guard instead (when we have solved the problem in route guard's timeout that slow get-user responses will redirect to login without waiting for an eror)
          break;
        case 'User not found':
          errorMessage = 'error.not_found_must_be_registered';
          break;
        case 'Only the current owner can set a new owner':
          errorMessage = 'error.permissions_lacking';
          break;
        case '400 Duplicate - already has this role in this hub':
          errorMessage = 'error.no_change_user_has_role_aready';
          break;
        case '403 Removing this role from the owner is not allowed':
          errorMessage = 'hubs.cannot_remove_owners_manager_role';
          break;
        case 'The given data was invalid.':
          if (
            errorResponse.error.errors?.file_url?.[0] ==
            'validation.ends_with_extension'
          ) {
            errorMessage = 'error.file_type';
          } else {
            errorMessage = 'error.data_invalid';
          }
          break;
        default:
        // no action needed. We already set the default error message.
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
}

